import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import apiService from "../api_service";
import {
    LIST_PUSH_NOTIFICATIONS_SEND,
    LIST_VIEWS,
    SEND_PUSH_NOTIFICATIONS,
    SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST,
    TEST_QUERY_BUILDER_FILTERS
} from "./constants";
import {PUSH_NOTIFICATIONS_ROOT_PATH} from "../../routing/paths";

import {
    listPushNotificationsSentError,
    listPushNotificationsSentSuccess,
    listViewsError,
    listViewsSuccess,
    sendPushNotificationsError,
    sendPushNotificationsSuccess,
    testQueryBuilderFiltersError,
    testQueryBuilderFiltersSuccess
} from "./actions";
import {
    LIST_PUSH_NOTIFICATIONS_SEND_PATH,
    LIST_VIEWS_PATH,
    SEND_PUSH_NOTIFICATIONS_PATH,
    SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_PATH,
    TEST_QUERY_BUILDER_FILTERS_PATH
} from "./paths";

//#######################
// LIST
export function* watchListViews() {
    yield takeEvery(LIST_VIEWS, listViewsSaga);
}

export const listViewsSagaAsync = async () => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: LIST_VIEWS_PATH
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* listViewsSaga() {
    try {
        const response = yield call(listViewsSagaAsync);
        if (response.status === "OK") {
            yield put(listViewsSuccess(response));
        } else {
            yield put(listViewsError(response));
        }
    } catch (e) {
        yield put(listViewsError(e.data ? e.data : {}));
    }
}

// SEND PUSH NOTIFICATIONS
export function* watchSendPushNotifications() {
    yield takeEvery(SEND_PUSH_NOTIFICATIONS, sendPushNotificationSaga);
}

export const sendPushNotificationSagaAsync = async (body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "POST",
            path: SEND_PUSH_NOTIFICATIONS_PATH,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* sendPushNotificationSaga({payload, history}) {
    try {
        const response = yield call(sendPushNotificationSagaAsync, payload);
        if (response.status === "OK") {
            yield put(sendPushNotificationsSuccess(response));
            window.location.reload()
        } else {
            yield put(sendPushNotificationsError(response));
        }
    } catch (e) {
        console.log("Error");
        yield put(sendPushNotificationsError(e.data ? e.data : {}));
    }
}

// SEND PUSH NOTIFICATIONS WITH CUSTOM LIST
export function* watchSendPushNotificationsWithCustomList() {
    yield takeEvery(
        SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST,
        sendPushNotificationSagaWithCustom
    );
}

export const sendPushNotificationSagaWithCustomAsync = async (body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "POST",
            path: SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_PATH,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* sendPushNotificationSagaWithCustom({payload, history}) {
    try {
        const response = yield call(
            sendPushNotificationSagaWithCustomAsync,
            payload
        );
        if (response.status === "OK") {
            yield put(sendPushNotificationsSuccess(response));
            history.push(PUSH_NOTIFICATIONS_ROOT_PATH);
        } else {
            yield put(sendPushNotificationsError(response));
        }
    } catch (e) {
        console.log("Error");
        yield put(sendPushNotificationsError(e.data ? e.data : {}));
    }
}

// END LIST
//#######################

export function* watchListPushNotificationsSend() {
    yield takeEvery(LIST_PUSH_NOTIFICATIONS_SEND, listPushNotificationSendSaga);
}

export const listPushNotificationSendSagaAsync = async (params) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "POST",
            path: LIST_PUSH_NOTIFICATIONS_SEND_PATH,
            params: params
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* listPushNotificationSendSaga({payload}) {
    try {
        const response = yield call(listPushNotificationSendSagaAsync, payload);
        if (response.status === "OK") {
            yield put(listPushNotificationsSentSuccess(response));
        } else {
            yield put(listPushNotificationsSentError(response));
        }
    } catch (e) {
        console.log("Error");
        yield put(listPushNotificationsSentError(e.data ? e.data : {}));
    }
}

// LIST PUSH NOTIFICATIONS SEND
//#######################

export function* watchTestQueryBuilder() {
    yield takeEvery(TEST_QUERY_BUILDER_FILTERS, testQueryBuilderSaga);
}

export const testQueryBuilderSagaAsync = async (params) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "POST",
            path: TEST_QUERY_BUILDER_FILTERS_PATH,
            body: params
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* testQueryBuilderSaga({payload}) {
    console.log(payload);
    try {
        const response = yield call(testQueryBuilderSagaAsync, payload);
        if (response.status === "OK") {
            yield put(testQueryBuilderFiltersSuccess(response));
        } else {
            yield put(testQueryBuilderFiltersError(response));
        }
    } catch (e) {
        console.log("Error");
        yield put(testQueryBuilderFiltersError(e.data ? e.data : {}));
    }
}

// LIST PUSH NOTIFICATIONS SEND
//#######################

export default function* rootSaga() {
    yield all([
        fork(watchListViews),
        fork(watchSendPushNotifications),
        fork(watchListPushNotificationsSend),
        fork(watchSendPushNotificationsWithCustomList),
        fork(watchTestQueryBuilder)
    ]);
}
