import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import apiService from "../api_service";
import {
    CREATE,
    DELETE_CELEBRITY_SECTION,
    DELETE_ONE,
    GET,
    GET_CELEBRITY_SECTION_DATA,
    LIST,
    NOT_PAGINATED_LIST,
    POST,
    PUT,
    UPDATE,
    UPDATE_POSITIONS
} from "./constants";
import {
    createError,
    createSuccess,
    deleteCelebritySectionError,
    deleteCelebritySectionSuccess,
    deleteOneError,
    deleteOneSuccess,
    getCelebritySectionDataError,
    getCelebritySectionDataSuccess,
    getError,
    getSuccess,
    listError,
    listSuccess,
    notPaginatedListError,
    notPaginatedListSuccess,
    postError,
    postSuccess,
    putError,
    putSuccess,
    updateError,
    updatePositionsError,
    updatePositionsSuccess,
    updateSuccess
} from "./actions";
import {
    CREATE_PATH,
    CRUD_DELETE_PATH,
    CRUD_GET_PATH,
    CRUD_POST_PATH,
    CRUD_PUT_PATH,
    DELETE_CELEBRITY_SECTION_PATH,
    GET_CELEBRITY_SECTION_DATA_PATH,
    LIST_PATH,
    NOT_PAGINATED_LIST_PATH,
    UPDATE_PATH,
    UPDATE_POSITIONS_PATH
} from "./paths";
import {NotificationManager} from "../../components/common/react-notifications";
import {LANDINGS_LIST_PATH} from "../../routing/paths";

//#######################
// LIST
export function* watchList() {
    yield takeEvery(LIST, listSaga);
}

export const listSagaAsync = async (params) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: LIST_PATH,
            params
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response.data : {}));
    });
};

function* listSaga({payload}) {
    try {
        const response = yield call(listSagaAsync, payload);
        if (response.status === "OK") {
            yield put(listSuccess(response));
        } else {
            yield put(listError(response));
        }
    } catch (e) {
        yield put(listError(e));
    }
}

// END LIST
//#######################

//#######################
// GET
export function* watchGet() {
    yield takeEvery(GET, getSaga);
}

const getSagaAsync = async ({objectID, params}) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_GET_PATH + objectID,
            params
        })
            .then((res) => resolve(res.data))
            .catch((err) =>
                reject(
                    err.response
                        ? err.response.data
                        : {status: "ERROR", error: "Ha ocurrido un error"}
                )
            );
    });
};

function* getSaga({payload}) {
    try {
        const response = yield call(getSagaAsync, payload);
        if (response.status === "OK") {
            yield put(getSuccess(response));
        } else {
            yield put(getError(response));
        }
    } catch (error) {
        yield put(getError(error));
    }
}

// END GET
//#######################

//#######################
// POST
export function* watchPost() {
    yield takeEvery(POST, postSaga);
}

const postSagaAsync = async (body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "POST",
            path: CRUD_POST_PATH,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response.data : {}));
    });
};

function* postSaga({payload}) {
    try {
        const response = yield call(postSagaAsync, payload);
        if (response.status === "OK") {
            yield put(postSuccess(response));
        } else {
            yield put(postError(response));
        }
    } catch (e) {
        yield put(postError({}));
    }
}

// END POST
//#######################

//#######################
// PUT
export function* watchPut() {
    yield takeEvery(PUT, putSaga);
}

const putSagaAsync = async (objectId, body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "PUT",
            path: CRUD_PUT_PATH + objectId,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response.data : {}));
    });
};

function* putSaga({payload}) {
    try {
        const response = yield call(putSagaAsync, payload);
        if (response.status === "OK") {
            yield put(putSuccess(response));
        } else {
            yield put(putError(response));
        }
    } catch (e) {
        yield put(putError({}));
    }
}

// END PUT
//#######################

//#######################
// DELETE
export function* watchDeleteOne() {
    yield takeEvery(DELETE_ONE, deleteOneSaga);
}

const deleteOneSagaAsync = async (objectId) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "DELETE",
            path: CRUD_DELETE_PATH + objectId
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response.data : {}));
    });
};

function* deleteOneSaga({payload}) {
    try {
        const response = yield call(deleteOneSagaAsync, payload);
        if (response.status === "OK") {
            yield put(deleteOneSuccess(response));
        } else {
            yield put(deleteOneError(response));
        }
    } catch (e) {
        yield put(deleteOneError({}));
    }
}

// END DELETE
//#######################
//#######################
// CREATE
export function* watchCreate() {
    yield takeEvery(CREATE, create);
}

const createAsync = async (body) => {
    try {
        const response = await apiService({
            async: true,
            path: CREATE_PATH,
            method: "POST",
            params: null,
            body
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : {error: "Error inesperado del servidor", status: "ERROR"};
    }
};

function* create({payload, history}) {
    try {
        const createData = yield call(createAsync, payload);
        if (createData.status !== "OK") {
            throw createData;
        }
        yield put(createSuccess());
        NotificationManager.success(
            "La sección a sido creado exitosamente",
            "Completado",
            3000
        );
        history.push(LANDINGS_LIST_PATH);
    } catch (error) {
        yield put(createError(error));
        NotificationManager.warning(error.message || error.error, "Oops!", 3000);
    }
}

// END CREATE
//#######################

//#######################
// UPDATE
export function* watchUpdate() {
    yield takeEvery(UPDATE, update);
}

const updateAsync = async (body) => {
    try {
        const response = await apiService({
            async: true,
            path: UPDATE_PATH,
            method: "PUT",
            params: null,
            body
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : {error: "Error inesperado del servidor", status: "ERROR"};
    }
};

function* update({payload, history}) {
    try {
        const updateData = yield call(updateAsync, payload);
        if (updateData.status !== "OK") {
            throw updateData;
        }
        yield put(updateSuccess());
        NotificationManager.success(
            "La sección a sido actualizada exitosamente",
            "Completado",
            3000
        );
        history.push(LANDINGS_LIST_PATH);
    } catch (error) {
        yield put(updateError(error));
        NotificationManager.warning(error.message || error.error, "Oops!", 3000);
    }
}

// END UPDATE
//#######################
//#######################
// UPDATE
export function* watchGetCelebritySectionData() {
    yield takeEvery(GET_CELEBRITY_SECTION_DATA, getCelebritySectionData);
}

const getCelebritySectionDataAsync = async (celebritySectionId) => {
    try {
        const response = await apiService({
            async: true,
            path: GET_CELEBRITY_SECTION_DATA_PATH + celebritySectionId,
            method: "GET"
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : {error: "Error inesperado del servidor", status: "ERROR"};
    }
};

function* getCelebritySectionData({payload}) {
    try {
        const getCelebritySectionDataData = yield call(
            getCelebritySectionDataAsync,
            payload
        );
        if (getCelebritySectionDataData.status !== "OK") {
            throw getCelebritySectionDataData;
        }
        yield put(getCelebritySectionDataSuccess(getCelebritySectionDataData));
    } catch (error) {
        yield put(getCelebritySectionDataError(error));
        NotificationManager.warning(error.message || error.error, "Oops!", 3000);
    }
}

// END UPDATE
//#######################
//#######################
// UPDATE
export function* watchDeleteCelebritySection() {
    yield takeEvery(DELETE_CELEBRITY_SECTION, deleteCelebritySection);
}

const deleteCelebritySectionAsync = async (celebritySectionId) => {
    try {
        const response = await apiService({
            async: true,
            path: DELETE_CELEBRITY_SECTION_PATH + celebritySectionId,
            method: "DELETE"
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : {error: "Error inesperado del servidor", status: "ERROR"};
    }
};

function* deleteCelebritySection({payload, history}) {
    try {
        const deleteCelebritySectionData = yield call(
            deleteCelebritySectionAsync,
            payload
        );
        if (deleteCelebritySectionData.status !== "OK") {
            throw deleteCelebritySectionData;
        }
        yield put(deleteCelebritySectionSuccess(deleteCelebritySectionData));
        NotificationManager.success(
            "La sección a sido eliminada exitosamente",
            "Completado",
            3000
        );
        history.push(LANDINGS_LIST_PATH);
    } catch (error) {
        yield put(deleteCelebritySectionError(error));
        NotificationManager.warning(error.message || error.error, "Oops!", 3000);
    }
}

// END UPDATE
//#######################

export function* watchNotPaginatedList() {
    yield takeEvery(NOT_PAGINATED_LIST, notPaginatedList);
}

const notPaginatedListAsync = async (params) => {
    try {
        const response = await apiService({
            async: true,
            path: NOT_PAGINATED_LIST_PATH,
            method: "GET",
            params
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : {error: "Error inesperado del servidor", status: "ERROR"};
    }
};

function* notPaginatedList({payload}) {
    try {
        const notPaginatedListData = yield call(notPaginatedListAsync, payload);
        if (notPaginatedListData.status !== "OK") {
            throw notPaginatedListData;
        }
        yield put(notPaginatedListSuccess(notPaginatedListData));
    } catch (error) {
        yield put(notPaginatedListError(error));
        NotificationManager.warning(error.message || error.error, "Oops!", 3000);
    }
}

export function* watchUpdatePositions() {
    yield takeEvery(UPDATE_POSITIONS, updatePositions);
}

const updatePositionsAsync = async (body) => {
    try {
        const response = await apiService({
            async: true,
            path: UPDATE_POSITIONS_PATH,
            method: "PUT",
            body
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : {error: "Error inesperado del servidor", status: "ERROR"};
    }
};

function* updatePositions({payload, history}) {
    try {
        const updatePositionsData = yield call(updatePositionsAsync, payload);
        if (updatePositionsData.status !== "OK") {
            throw updatePositionsData;
        }
        yield put(updatePositionsSuccess(updatePositionsData));
        NotificationManager.success(
            "Las secciones han sido ordenadas exitosamente",
            "Completado",
            3000
        );
        history.push(LANDINGS_LIST_PATH);
    } catch (error) {
        yield put(updatePositionsError(error));
        NotificationManager.warning(error.message || error.error, "Oops!", 3000);
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchList),
        fork(watchGet),
        fork(watchPut),
        fork(watchPost),
        fork(watchDeleteOne),
        fork(watchCreate),
        fork(watchUpdate),
        fork(watchDeleteCelebritySection),
        fork(watchGetCelebritySectionData),
        fork(watchNotPaginatedList),
        fork(watchUpdatePositions)
    ]);
}
