import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import apiService from "../api_service";
import {GET, LIST, POST, PUT} from "./constants";
import {getError, getSuccess, listError, listSuccess, postError, postSuccess, putError, putSuccess} from "./actions";
import {CRUD_GET_PATH, CRUD_LIST_PATH, CRUD_POST_PATH, CRUD_PUT_PATH} from "./paths";

//#######################
// LIST
export function* watchList() {
    yield takeEvery(LIST, listSaga);
}

export const listSagaAsync = async (params) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_LIST_PATH,
            params
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* listSaga({payload}) {
    try {
        const response = yield call(listSagaAsync, payload);
        if (response.status === "OK") {
            yield put(listSuccess(response));
        } else {
            yield put(listError(response));
        }
    } catch (e) {
        yield put(listError(e.data ? e.data : {}));
    }
}

// END LIST
//#######################

//#######################
// GET
export function* watchGet() {
    yield takeEvery(GET, getSaga);
}

const getSagaAsync = async (objectId) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_GET_PATH + objectId
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* getSaga({payload}) {
    try {
        const response = yield call(getSagaAsync, payload);
        if (response.status === "OK") {
            yield put(getSuccess(response));
        } else {
            yield put(getError(response));
        }
    } catch (e) {
        yield put(getError(e.data ? e.data : {}));
    }
}

// END GET
//#######################

//#######################
// POST
export function* watchPost() {
    yield takeEvery(POST, postSaga);
}

export const postSagaAsync = async (body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "POST",
            path: CRUD_POST_PATH,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* postSaga({payload, history}) {
    try {
        const response = yield call(postSagaAsync, payload);
        if (response.status === "OK") {
            yield put(postSuccess(response));
            // history.push(COUPONS_DETAILS_PATH.replace(":id", response.data.id))
        } else {
            yield put(postError(response));
        }
    } catch (e) {
        yield put(postError(e.data ? e.data : {}));
    }
}

// END POST
//#######################

//#######################
// PUT
export function* watchPut() {
    yield takeEvery(PUT, putSaga);
}

export const putSagaAsync = async (body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "PUT",
            path: CRUD_PUT_PATH + body.id,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* putSaga({payload, history}) {
    try {
        const response = yield call(putSagaAsync, payload);
        if (response.status === "OK") {
            yield put(putSuccess(response));
        } else {
            yield put(putError(response));
        }
    } catch (e) {
        yield put(putError(e.data ? e.data : {}));
    }
}


export default function* rootSaga() {
    yield all([fork(watchList), fork(watchGet), fork(watchPut), fork(watchPost)]);
}
