import {MODEL_PATH} from "./constants";

//##############################################################
// CUSTOM ENDPOINTS
export const LIST_VIEWS_PATH =
    "custom-endpoints/" + MODEL_PATH + "/list-available-views";

export const SEND_PUSH_NOTIFICATIONS_PATH =
    "custom-endpoints/" + MODEL_PATH + "/send-push-notification";

export const LIST_PUSH_NOTIFICATIONS_SEND_PATH =
    "custom-endpoints/" + MODEL_PATH + "/list-notifications-sent";

export const GET_DETAILS_PUSH_NOTIFICATIONS_SEND_PATH =
    "crud/list/" + MODEL_PATH + "/list-notifications-sent";
export const SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_PATH =
    "custom-endpoints/" + MODEL_PATH + "/send-notification-with-custom-list";

export const TEST_QUERY_BUILDER_FILTERS_PATH =
    "custom-endpoints/" + MODEL_PATH + "/test-query-builder-filters";

// END CUSTOM ENDPOINTS
//##############################################################
