import { defaultDirection } from "../../constants/defaultValues";

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "." + mm + "." + yyyy;
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes();
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl"
  };
};

export const setDirection = (localValue) => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};

export const formatNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatDate = (str) => {
  const d = new Date(str);
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
};

export const limitString = (string, limit = 17) => {
  const newString = [];
  if (string.length < limit) return string;

  let characterCount = 0;
  const words = string.split(" ");
  for (let index = 0; index < words.length; index += 1) {
    const word = words[index];
    const isInLimit = characterCount + word.length <= limit;
    if (!isInLimit) {
      break;
    }

    newString.push(word);
    characterCount += word.length;
  }

  return `${newString.join(" ")} ...`;
};

export const hasOnlyUniqueItems = (list, mapFunction) => {
  if (!Array.isArray(list)) {
    throw new TypeError("The arg 'list' must be an array");
  }
  if (mapFunction && typeof mapFunction !== "function") {
    throw new TypeError("The arg 'mapFunction' must be a function");
  }
  const listOfMappedItems = mapFunction ? list.map(mapFunction) : list;
  return listOfMappedItems.length === new Set(listOfMappedItems).size;
};

export const pickPropertiesFromAObject = (object, allowedKeys) => {
  if (typeof object !== "object") {
    throw new TypeError("The argument 'object' must be an object");
  }
  if (!Array.isArray(allowedKeys)) {
    throw new TypeError("The argument 'allowedKeys' must be an array");
  }
  if (allowedKeys.some((item) => typeof item !== "string")) {
    throw new TypeError("The argument 'allowedKeys' must only has strings");
  }

  const entries = Object.entries(object);
  const filteredEntries = entries.filter(([key]) => allowedKeys.includes(key));
  return filteredEntries.reduce(
    (newObject, [key, value]) => ({ ...newObject, [key]: value }),
    {}
  );
};

export const getFormattedPrice = (price = 0) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  }).format(price);
};
