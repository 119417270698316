import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import apiService from "../api_service";
import {
    GET,
    LIST,
} from "./constants";

import {
    getSuccess,
    getError,
    listError,
    listSuccess,
} from "./actions";
import {
    CUSTOM_POST_PATH,
    CRUD_GET_PATH,
    CRUD_LIST_PATH,
} from "./paths";

import { CELEBRITIES_EDITOR_PATH } from "../../routing/paths";

import { NotificationManager } from "../../components/common/react-notifications";

//#######################
// LIST
export function* watchList() {
    yield takeEvery(LIST, listSaga);
}

export const listSagaAsync = async (params) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_LIST_PATH,
            params
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* listSaga({payload}) {
    try {
        const response = yield call(listSagaAsync, payload);
        if (response.status === "OK") {
            yield put(listSuccess(response));
        } else {
            yield put(listError(response));
        }
    } catch (e) {
        yield put(listError(e.data ? e.data : {}));
    }
}

// END LIST
//#######################

//#######################
// GET
export function* watchGet() {
    yield takeEvery(GET, getSaga);
}

const getSagaAsync = async (id) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_GET_PATH + id
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

export const getAddOns = async (params) =>{
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_LIST_PATH,
            params
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
}

function* getSaga({ payload }) {
    try {
        const response = yield call(getSagaAsync, payload);
        if (response.status === "OK") {
            yield put(getSuccess(response));
        } else {
            yield put(getError(response));
        }
    } catch (e) {
        yield put(getError(e.data ? e.data : {}));
    }
}

//custom

export function postAddOns (payload){
    return new Promise(function (resolve, reject) {
        apiService({
            method: "POST",
            path: CUSTOM_POST_PATH,
            body:payload
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
}

//END GET REFERRAL OR MANAGER
export default function* rootSaga() {
    yield all([
        fork(watchList),
        fork(watchGet)
    ]);
}
