import { MODEL_PATH } from "./constants";

//##############################################################
// CRUD
export const CRUD_LIST_PATH = "crud/list/" + MODEL_PATH;
export const CRUD_POST_PATH = "crud/post/" + MODEL_PATH;
export const CRUD_GET_PATH = "crud/get/" + MODEL_PATH + "/";
export const CRUD_PUT_PATH = "crud/put/" + MODEL_PATH + "/";
export const CRUD_DELETE_PATH = "crud/delete/" + MODEL_PATH + "/";
export const CUSTOM_ENDPOINTS_SAVE =
  "custom-endpoints/" + MODEL_PATH + "/create";
export const CUSTOM_ENDPOINTS_UPDATE =
  "custom-endpoints/" + MODEL_PATH + "/update";
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom paths
export const GET_CELEBRITY_DETAILS_PATH =
  "custom-endpoints/" + MODEL_PATH + "/";
export const GET_BY_USERNAME_PATH =
  "custom-endpoints/" + MODEL_PATH + "/get-user-by-username/";
export const GET_INSTAGRAM_MEDIAS_PATH =
  "custom-endpoints/" + MODEL_PATH + "/get-user-instagram-medias/";
export const GET_CONTRACTS_BALANCE_PATH = `/custom-endpoints/${MODEL_PATH}/balance/`;
export const GET_CONTRACTS_GROUP_BY_STATUS_PATH = `/custom-endpoints/${MODEL_PATH}/group-contracts-by-status/`;
export const REJECT_CELEBRITY_PATH = `/custom-endpoints/${MODEL_PATH}/reject-celebrity`;
export const GET_REFERRAL_OR_MANAGER_PATH = `/custom-endpoints/${MODEL_PATH}/:celebrity_username/get-referral-or-manager-data`;

export const CELEBRITY_SEARCH_LIST_PATH = `custom-endpoints/celebrities/search-list`;
// CUSTOM ACTIONS
// ##############################################################
