import {ERROR_INITIAL_STATE, GET_INITIAL_STATE, POST_INITIAL_STATE} from "../initial_states";
import React from "react";
import IntlMessages from "../../components/helpers/IntlMessages";
import {NotificationManager} from "../../components/common/react-notifications";
import {
    LIST_PUSH_NOTIFICATIONS_SEND,
    LIST_PUSH_NOTIFICATIONS_SEND_ERROR,
    LIST_PUSH_NOTIFICATIONS_SEND_SUCCESS,
    LIST_VIEWS,
    LIST_VIEWS_ERROR,
    LIST_VIEWS_SUCCESS,
    SEND_PUSH_NOTIFICATIONS,
    SEND_PUSH_NOTIFICATIONS_ERROR,
    SEND_PUSH_NOTIFICATIONS_SUCCESS,
    SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST,
    SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_ERROR,
    SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_SUCCESS,
    TEST_QUERY_BUILDER_FILTERS,
    TEST_QUERY_BUILDER_FILTERS_ERROR,
    TEST_QUERY_BUILDER_FILTERS_SUCCESS
} from "./constants";

const LIST_VIEWS_INITIAL_STATE = {
    loading: false,
    errorData: ERROR_INITIAL_STATE,
    data: []
};
const INIT_STATE = {
    // CUSTOM ENDPOINTS
    list_views: LIST_VIEWS_INITIAL_STATE,
    list_push_notifications_send: LIST_VIEWS_INITIAL_STATE,
    send_push_notifications: POST_INITIAL_STATE,
    testQueryBuilder: GET_INITIAL_STATE
};

export default (state = INIT_STATE, action) => {
    let res;
    switch (action.type) {
        //##############################################################
        // CUSTOM ENDPOINTS
        case LIST_VIEWS:
            res = {...state};
            res.list_views = {...LIST_VIEWS_INITIAL_STATE};
            res.list_views.loading = true;
            return res;
        case LIST_VIEWS_SUCCESS:
            res = {...state};
            res.list_views = {...LIST_VIEWS_INITIAL_STATE};
            res.list_views.data = action.payload.data;
            return res;
        case LIST_VIEWS_ERROR:
            res = {...state};
            res.list_views = {...LIST_VIEWS_INITIAL_STATE};
            res.list_views.errorData = action.payload;
            NotificationManager.warning(
                res.list_views.errorData.error,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        case SEND_PUSH_NOTIFICATIONS:
            res = {...state};
            res.send_push_notifications = {...POST_INITIAL_STATE};
            res.send_push_notifications.loading = true;
            return res;
        case SEND_PUSH_NOTIFICATIONS_SUCCESS:
            res = {...state};
            res.send_push_notifications = {...POST_INITIAL_STATE};
            res.send_push_notifications.data = action.payload.data;
            NotificationManager.success(
                <IntlMessages id='notifications.notifications-push.sucess'/>,
                10000,
                null,
                null,
                ""
            );
            return res;
        case SEND_PUSH_NOTIFICATIONS_ERROR:
            res = {...state};
            res.send_push_notifications = {...POST_INITIAL_STATE};
            res.send_push_notifications.errorData = action.payload;
            NotificationManager.warning(
                res.send_push_notifications.errorData.error,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        case SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST:
            res = {...state};
            res.send_push_notifications_with_custom_list = {...POST_INITIAL_STATE};
            res.send_push_notifications_with_custom_list.loading = true;
            return res;
        case SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_SUCCESS:
            res = {...state};
            res.send_push_notifications_with_custom_list = {...POST_INITIAL_STATE};
            res.send_push_notifications_with_custom_list.data = action.payload.data;
            NotificationManager.success(
                <IntlMessages id='notifications.notifications-push.sucess'/>,
                10000,
                null,
                null,
                ""
            );
            return res;
        case SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_ERROR:
            res = {...state};
            res.send_push_notifications_with_custom_list = {...POST_INITIAL_STATE};
            res.send_push_notifications_with_custom_list.errorData = action.payload;
            NotificationManager.warning(
                res.send_push_notifications.errorData.error,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        case LIST_PUSH_NOTIFICATIONS_SEND:
            res = {...state};
            res.list_push_notifications_send = {...LIST_VIEWS_INITIAL_STATE};
            res.list_push_notifications_send.loading = true;
            return res;
        case LIST_PUSH_NOTIFICATIONS_SEND_SUCCESS:
            res = {...state};
            res.list_push_notifications_send = {...LIST_VIEWS_INITIAL_STATE};
            res.list_push_notifications_send.data = action.payload.data;
            return res;
        case LIST_PUSH_NOTIFICATIONS_SEND_ERROR:
            res = {...state};
            res.list_push_notifications_send = {...LIST_VIEWS_INITIAL_STATE};
            res.list_push_notifications_send.errorData = action.payload;
            NotificationManager.warning(
                res.list_views.errorData.error,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        case TEST_QUERY_BUILDER_FILTERS:
            res = {...state};
            res.testQueryBuilder = {...GET_INITIAL_STATE};
            res.testQueryBuilder.loading = true;
            return res;
        case TEST_QUERY_BUILDER_FILTERS_SUCCESS:
            res = {...state};
            res.testQueryBuilder = {...GET_INITIAL_STATE};
            res.testQueryBuilder.data = action.payload.data;
            return res;
        case TEST_QUERY_BUILDER_FILTERS_ERROR:
            res = {...state};
            res.testQueryBuilder = {...GET_INITIAL_STATE};
            res.testQueryBuilder.errorData = action.payload;
            NotificationManager.warning(
                res.list_views.errorData.error,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        // CUSTOM ENDPOINTS
        //##############################################################

        default:
            return {...state};
    }
};
