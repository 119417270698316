import { MODEL_PATH, MODEL_REACTION } from "./constants";

//##############################################################
// CRUD
export const CRUD_LIST_PATH = "crud/list/" + MODEL_PATH;
export const CRUD_POST_PATH = "crud/post/" + MODEL_PATH;
export const CRUD_GET_PATH = "crud/get/" + MODEL_PATH + "/";
export const CRUD_PUT_PATH = "crud/put/" + MODEL_PATH + "/";
export const CRUD_DELETE_PATH = "crud/delete/" + MODEL_PATH + "/";
// CRUD REACTION
export const CRUD_LIST_PATH_REACTIONS = "crud/list/" + MODEL_REACTION;

//##############################################################

export const CUSTOM_ENDPOINTS_ACCEPT_OR_REJECT_CONTRACT_PATH = `custom-endpoints/${MODEL_PATH}/accept-or-reject-contract`;
export const CUSTOM_ENDPOINTS_REJECT_CONTRACT_PATH = `custom-endpoints/${MODEL_PATH}/reject-contract`;
export const CUSTOM_ENDPOINTS_ACCEPT_CONTRACT_PATH = `custom-endpoints/${MODEL_PATH}/accept-contract`;
export const CUSTOM_ENDPOINTS_GET_UPLOAD_LINK = `custom-endpoints/${MODEL_PATH}/get-upload-link`;
export const CUSTOM_ENDPOINTS_ACTIVE_CONTRACT = `custom-endpoints/${MODEL_PATH}/active-contract`;
export const CUSTOM_ENDPOINTS_REMOVE_CONTRACT_MEDIA_CACHE = `custom-endpoints/${MODEL_PATH}/remove-contract-media-cache`;
export const CUSTOM_ENDPOINTS_CUSTOM_UPDATE = `custom-endpoints/${MODEL_PATH}/custom-update`;
export const CUSTOM_ENDPOINTS_MARK_AS_CHARGEBACK = `custom-endpoints/${MODEL_PATH}/mark-contract-as-chargeback`;
export const CUSTOM_ENDPOINTS_REJECT_AND_BLOCK_USER = `custom-endpoints/${MODEL_PATH}/reject-and-block-user`;
export const CUSTOM_ENDPOINTS_REQUEST_URL_FOR_POST = `custom-endpoints/${MODEL_REACTION}/get-upload-reaction-link`;
export const CUSTOM_ENDPOINTS_POST_URL_VIDEO_RACTION = `custom-endpoints/${MODEL_REACTION}/save-contract-reaction`;
export const CUSTOM_ENDPOINTS_MARK_CONTRACT_AS_FRAUD_REVISION_PATH = `custom-endpoints/${MODEL_PATH}/mark-contract-as-fraud-revision`;
export const CUSTOM_ENDPOINTS_REACTIVATE_FRAUD_REVISION_CONTRACT_PATH = `custom-endpoints/${MODEL_PATH}/reactivate-fraud-revision-contract`;
