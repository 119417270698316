import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";

const App = React.lazy(() => import("./App"));

// Método de renderizado anterior
// ReactDOM.render(
//   <Provider store={configureStore()}>
//     <Suspense fallback={<div className="loading" />}>
//       <App />
//     </Suspense>
//   </Provider>,
//   document.getElementById("root")
// );

const reduxStore = configureStore();

// Nuevo método de rendizado con HMR
const render = (Component) => {
  return ReactDOM.render(
    <Provider store={reduxStore}>
      <Suspense fallback={<div className="loading" />}>
        <Component />
      </Suspense>
    </Provider>,
    document.getElementById("root")
  );
};

render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}

serviceWorker.unregister();
