const MODEL_PATH = "authentication";

//##############################################################
// CRUD

// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom paths
export const LOGIN_PATH = "custom-endpoints/" + MODEL_PATH + '/login/email-password';
// CUSTOM ACTIONS
// ##############################################################
