import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import apiService from "../api_service";
import {
    CREATE,
    DELETE_ONE,
    GET,
    GET_BY_USERNAME,
    GET_CELEBRITIES,
    GET_CONTRACTS,
    GET_CONTRACTS_BALANCE,
    LINK_CELEBRITIES,
    LIST,
    POST,
    PUT,
    UPDATE
} from "./constants";
import {
    createError,
    createSuccess,
    deleteOneError,
    deleteOneSuccess,
    getByUsernameError,
    getByUsernameSuccess,
    getCelebritiesError,
    getCelebritiesSuccess,
    getContractsBalanceError,
    getContractsBalanceSuccess,
    getContractsError,
    getContractsSuccess,
    getError,
    getSuccess,
    linkCelebritiesError,
    linkCelebritiesSuccess,
    listError,
    listSuccess,
    postError,
    postSuccess,
    putError,
    putSuccess,
    updateError,
    updateSuccess
} from "./actions";
import {
    CREATE_PATH,
    CRUD_DELETE_PATH,
    CRUD_GET_PATH,
    CRUD_LIST_PATH,
    CRUD_POST_PATH,
    CRUD_PUT_PATH,
    GET_BY_USERNAME_PATH,
    GET_CELEBRITIES_GROUP_BY_STATUS_SUMMARY,
    GET_CELEBRITIES_PATH,
    GET_CONTRACTS_BALANCE_PATH,
    GET_CONTRACTS_GROUP_BY_STATUS_PATH,
    GET_CONTRACTS_PATH,
    GET_MANAGER_AGGREMENT_PATH,
    LINK_CELEBRITIES_PATH,
    UPDATE_PATH
} from "./paths";
import {NotificationManager} from "../../components/common/react-notifications";
import {MANAGERS_DETAILS_PATH, MANAGERS_LIST_PATH} from "../../routing/paths";
import {pickPropertiesFromAObject} from "../../components/helpers/Utils";

//#######################
// LIST
export function* watchList() {
    yield takeEvery(LIST, listSaga);
}

export const listSagaAsync = async (params) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_LIST_PATH,
            params
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response.data : {}));
    });
};

function* listSaga({payload}) {
    try {
        const response = yield call(listSagaAsync, payload);
        if (response.status === "OK") {
            yield put(listSuccess(response));
        } else {
            yield put(listError(response));
        }
    } catch (e) {
        yield put(listError(e));
    }
}

// END LIST
//#######################

//#######################
// GET
export function* watchGet() {
    yield takeEvery(GET, getSaga);
}

const getSagaAsync = async (objectId) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_GET_PATH + objectId
        })
            .then((res) => resolve(res.data))
            .catch((err) =>
                reject(
                    err.response
                        ? err.response.data
                        : {status: "ERROR", error: "Ha ocurrido un error"}
                )
            );
    });
};

function* getSaga({payload}) {
    try {
        const response = yield call(getSagaAsync, payload);
        if (response.status === "OK") {
            yield put(getSuccess(response));
        } else {
            yield put(getError(response));
        }
    } catch (error) {
        yield put(getError(error));
    }
}

// END GET
//#######################

//#######################
// POST
export function* watchPost() {
    yield takeEvery(POST, postSaga);
}

const postSagaAsync = async (body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "POST",
            path: CRUD_POST_PATH,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response.data : {}));
    });
};

function* postSaga({payload}) {
    try {
        const response = yield call(postSagaAsync, payload);
        if (response.status === "OK") {
            yield put(postSuccess(response));
        } else {
            yield put(postError(response));
        }
    } catch (e) {
        yield put(postError({}));
    }
}

// END POST
//#######################

//#######################
// PUT
export function* watchPut() {
    yield takeEvery(PUT, putSaga);
}

const putSagaAsync = async (objectId, body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "PUT",
            path: CRUD_PUT_PATH + objectId,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response.data : {}));
    });
};

function* putSaga({payload}) {
    try {
        const response = yield call(putSagaAsync, payload);
        if (response.status === "OK") {
            yield put(putSuccess(response));
        } else {
            yield put(putError(response));
        }
    } catch (e) {
        yield put(putError({}));
    }
}

// END PUT
//#######################

//#######################
// DELETE
export function* watchDeleteOne() {
    yield takeEvery(DELETE_ONE, deleteOneSaga);
}

const deleteOneSagaAsync = async (objectId) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "DELETE",
            path: CRUD_DELETE_PATH + objectId
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response.data : {}));
    });
};

function* deleteOneSaga({payload}) {
    try {
        const response = yield call(deleteOneSagaAsync, payload);
        if (response.status === "OK") {
            yield put(deleteOneSuccess(response));
        } else {
            yield put(deleteOneError(response));
        }
    } catch (e) {
        yield put(deleteOneError({}));
    }
}

// END DELETE
//#######################

//#######################
// GET_BY_USERNAME
export function* watchGetByusername() {
    yield takeEvery(GET_BY_USERNAME, getByUsernameSaga);
}

const getByUsernameSagaAsync = async ({objectID, params}) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: GET_BY_USERNAME_PATH + objectID,
            params: params
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response.data : {}));
    });
};

function* getByUsernameSaga({payload}) {
    const response = yield call(getByUsernameSagaAsync, payload);
    if (response.status === "OK") {
        yield put(getByUsernameSuccess(response));
    } else {
        yield put(getByUsernameError(response));
    }
}

// END GET_BY_USERNAME
//#######################
//#######################
// GET_CELEBRITIES
export function* watchGetCelebrities() {
    yield takeEvery(GET_CELEBRITIES, getCelebritiesSaga);
}

const getCelebritiesSagaAsync = async ({managerId, params}) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: GET_CELEBRITIES_PATH.replace(":MANAGER_ID", managerId),
            params
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response.data : {}));
    });
};

function* getCelebritiesSaga({payload}) {
    const response = yield call(getCelebritiesSagaAsync, payload);
    if (response.status === "OK") {
        yield put(getCelebritiesSuccess(response));
    } else {
        yield put(getCelebritiesError(response));
    }
}

// END GET_CELEBRITIES
//#######################
//#######################
// CONTRACTS
export function* watchGetContracts() {
    yield takeEvery(GET_CONTRACTS, getContracts);
}

const getContractsAsync = async ({managerId, params}) => {
    try {
        const response = await apiService({
            async: true,
            path: GET_CONTRACTS_PATH.replace(":MANAGER_ID", managerId),
            method: "GET",
            params
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : {error: "Error inesperado del servidor"};
    }
};

function* getContracts({payload}) {
    try {
        const getContractsData = yield call(getContractsAsync, payload);
        if (getContractsData.status !== "OK") {
            throw getContractsData.error;
        }
        yield put(getContractsSuccess(getContractsData));
    } catch (error) {
        yield put(getContractsError(error));
        NotificationManager.warning(error, "Oops!", 3000);
    }
}

// END CONTRACTS
//#######################
//#######################
// CONTRACTS
export function* watchGetContractsBalance() {
    yield takeEvery(GET_CONTRACTS_BALANCE, getContractsBalance);
}

const getContractsBalanceAsync = async ({managerId, params}) => {
    try {
        const response = await apiService({
            async: true,
            path: GET_CONTRACTS_BALANCE_PATH.replace(":MANAGER_ID", managerId),
            method: "GET",
            params
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : {error: "Error inesperado del servidor"};
    }
};

function* getContractsBalance({payload}) {
    try {
        const getContractsBalanceData = yield call(
            getContractsBalanceAsync,
            payload
        );
        if (getContractsBalanceData.status !== "OK") {
            throw getContractsBalanceData.error;
        }
        yield put(getContractsBalanceSuccess(getContractsBalanceData.data));
    } catch (error) {
        yield put(getContractsBalanceError(error));
        NotificationManager.warning(error, "Oops!", 3000);
    }
}

// END CONTRACTS
//#######################
//#######################
// LINK CELEBRITIES
export function* watchLinkCelebrities() {
    yield takeEvery(LINK_CELEBRITIES, linkCelebrities);
}

const linkCelebritiesAsync = async ({managerId, ...body}) => {
    try {
        const response = await apiService({
            async: true,
            path: LINK_CELEBRITIES_PATH.replace(":MANAGER_ID", managerId),
            method: "PUT",
            body
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : {error: "Error inesperado del servidor"};
    }
};

function* linkCelebrities({payload, history}) {
    try {
        const linkCelebritiesData = yield call(linkCelebritiesAsync, payload);
        if (linkCelebritiesData.status !== "OK") {
            throw linkCelebritiesData.error;
        }
        yield put(linkCelebritiesSuccess());
        NotificationManager.success(
            "Los famosos han sido asociados exitosamente",
            "Completado",
            3000
        );
        history.push(MANAGERS_DETAILS_PATH.replace(":id", payload.managerId));
    } catch (error) {
        yield put(linkCelebritiesError(error));
        NotificationManager.warning(error, "Oops!", 3000);
    }
}

// END LINK CELEBRITIES
//#######################
//#######################
// CREATE
export function* watchCreate() {
    yield takeEvery(CREATE, create);
}

const createAsync = async (body) => {
    try {
        const response = await apiService({
            async: true,
            path: CREATE_PATH,
            method: "POST",
            params: null,
            body
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : {error: "Error inesperado del servidor"};
    }
};

function* create({payload, history}) {
    try {
        const createData = yield call(createAsync, payload);
        if (createData.status !== "OK") {
            throw createData.error;
        }
        yield put(createSuccess());
        NotificationManager.success(
            "El manager a sido creado exitosamente",
            "Completado",
            3000
        );
        history.push(MANAGERS_LIST_PATH);
    } catch (error) {
        yield put(createError(error));
        NotificationManager.warning(error, "Oops!", 3000);
    }
}

// END CREATE
//#######################
//#######################
// UPDATE
export function* watchUpdate() {
    yield takeEvery(UPDATE, update);
}

const updateAsync = async ({id: managerId, ...managerData}) => {
    const body = pickPropertiesFromAObject(managerData, [
        "avatar",
        "email",
        "fullName",
        "newPassword",
        "isAgency",
        "agencyName",
        "agencyLogo",
        "managerPercentage",
        "celebrityPercentage",
        "status"
    ]);
    try {
        const response = await apiService({
            async: true,
            path: UPDATE_PATH.replace(":MANAGER_ID", managerId),
            method: "PUT",
            params: null,
            body
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : {error: "Error inesperado del servidor"};
    }
};

function* update({payload, history}) {
    try {
        const updateData = yield call(updateAsync, payload);
        if (updateData.status !== "OK") {
            throw updateData.error;
        }
        yield put(updateSuccess());
        NotificationManager.success(
            "El manager a sido actualizado exitosamente",
            "Completado",
            3000
        );
        history.push(MANAGERS_LIST_PATH);
    } catch (error) {
        yield put(updateError(error));
        NotificationManager.warning(error, "Oops!", 3000);
    }
}

// END CREATE
//#######################
//#######################
// GET INSTAGRAM MEDIAS
export const getInstagramMediasSagaAsync = async (username) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: "https://www.instagram.com/" + username + "/?__a=1",
            custom_endpoint: true
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response.data : {}));
    });
};
// END GET INSTAGRAM MEDIAS
//#######################

export const contractsGroupByStatusSagaAsync = async (managerId) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: GET_CONTRACTS_GROUP_BY_STATUS_PATH.replace(
                ":MANAGER_ID",
                managerId
            ),
            params: null
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

export const celebritiesGroupByStatusSagaAsync = async (managerId) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: GET_CELEBRITIES_GROUP_BY_STATUS_SUMMARY.replace(
                ":MANAGER_ID",
                managerId
            ),
            params: null
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

export const getManagerAgreementSagaAsync = async (managerId) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: GET_MANAGER_AGGREMENT_PATH.replace(":MANAGER_ID", managerId),
            params: null
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

export default function* rootSaga() {
    yield all([
        fork(watchList),
        fork(watchGet),
        fork(watchPut),
        fork(watchPost),
        fork(watchCreate),
        fork(watchUpdate),
        fork(watchDeleteOne),
        fork(watchGetByusername),
        fork(watchGetContracts),
        fork(watchGetContractsBalance),
        fork(watchLinkCelebrities),
        fork(watchGetCelebrities)
    ]);
}
