export const MODEL_PATH = "celebrity-sections";

//##############################################################
// CRUD
export const POST = MODEL_PATH + "_POST";
export const POST_SUCCESS = MODEL_PATH + "_POST_SUCCESS";
export const POST_ERROR = MODEL_PATH + "_POST_ERROR";
export const LIST = MODEL_PATH + "_LIST";
export const LIST_SUCCESS = MODEL_PATH + "_LIST_SUCCESS";
export const LIST_ERROR = MODEL_PATH + "_LIST_ERROR";
export const GET = MODEL_PATH + "_GET";
export const GET_SUCCESS = MODEL_PATH + "_GET_SUCCESS";
export const GET_ERROR = MODEL_PATH + "_GET_ERROR";
export const PUT = MODEL_PATH + "_PUT";
export const PUT_SUCCESS = MODEL_PATH + "_PUT_SUCCESS";
export const PUT_ERROR = MODEL_PATH + "_PUT_ERROR";
export const DELETE_ONE = MODEL_PATH + "_DELETE_ONE";
export const DELETE_ONE_SUCCESS = MODEL_PATH + "_DELETE_ONE_SUCCESS";
export const DELETE_ONE_ERROR = MODEL_PATH + "_DELETE_ONE_ERROR";
export const NOT_PAGINATED_LIST = MODEL_PATH + "_NOT_PAGINATED_LIST";
export const NOT_PAGINATED_LIST_SUCCESS =
  MODEL_PATH + "_NOT_PAGINATED_LIST_SUCCESS";
export const NOT_PAGINATED_LIST_ERROR =
  MODEL_PATH + "_NOT_PAGINATED_LIST_ERROR";
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom action constants
export const CREATE = MODEL_PATH + "_CREATE";
export const CREATE_SUCCESS = MODEL_PATH + "_CREATE_SUCCESS";
export const CREATE_ERROR = MODEL_PATH + "_CREATE_ERROR";
export const UPDATE_POSITIONS = MODEL_PATH + "_UPDATE_POSITIONS";
export const UPDATE_POSITIONS_SUCCESS =
  MODEL_PATH + "_UPDATE_POSITIONS_SUCCESS";
export const UPDATE_POSITIONS_ERROR = MODEL_PATH + "_UPDATE_POSITIONS_ERROR";
export const UPDATE = MODEL_PATH + "_UPDATE";
export const UPDATE_SUCCESS = MODEL_PATH + "_UPDATE_SUCCESS";
export const UPDATE_ERROR = MODEL_PATH + "_UPDATE_ERROR";
export const GET_CELEBRITY_SECTION_DATA =
  MODEL_PATH + "_GET_CELEBRITY_SECTION_DATA";
export const GET_CELEBRITY_SECTION_DATA_SUCCESS =
  MODEL_PATH + "_GET_CELEBRITY_SECTION_DATA_SUCCESS";
export const GET_CELEBRITY_SECTION_DATA_ERROR =
  MODEL_PATH + "_GET_CELEBRITY_SECTION_DATA_ERROR";
export const DELETE_CELEBRITY_SECTION =
  MODEL_PATH + "_DELETE_CELEBRITY-SECTION";
export const DELETE_CELEBRITY_SECTION_SUCCESS =
  MODEL_PATH + "_DELETE_CELEBRITY-SECTION_SUCCESS";
export const DELETE_CELEBRITY_SECTION_ERROR =
  MODEL_PATH + "_DELETE_CELEBRITY-SECTION_ERROR";
// CUSTOM ACTIONS
// ##############################################################
