import {
    DELETE_ONE_INITIAL_STATE,
    GET_INITIAL_STATE,
    LIST_INITIAL_STATE,
    POST_INITIAL_STATE,
    PUT_INITIAL_STATE
} from "../initial_states";
import {NotificationManager} from "../../components/common/react-notifications";
import {
    GET,
    GET_ERROR,
    GET_SUCCESS,
    LIST,
    LIST_ERROR,
    LIST_SUCCESS,
} from "./constants";

const INIT_STATE = {
    // CRUD
    post: POST_INITIAL_STATE,
    put: PUT_INITIAL_STATE,
    list: LIST_INITIAL_STATE,
    get: GET_INITIAL_STATE,
    deleteOne: DELETE_ONE_INITIAL_STATE,
};

type action={type:string, payload:any}

export default (state = INIT_STATE, action:action ) => {
    const {type, payload} = action
    let res;
    switch (type) {
        //##############################################################
        // CRUD
        case LIST:
            res = {...state};
            res.list = {...LIST_INITIAL_STATE};
            res.list.loading = true;
            return res;
        case LIST_SUCCESS:
            res = {...state};
            res.list = {...LIST_INITIAL_STATE};
            res.list.results = payload.results;
            res.list.informationPage = payload.informationPage;
            return res;
        case LIST_ERROR:
            res = {...state};
            res.list = {...LIST_INITIAL_STATE};
            res.list.errorData = payload;
            // NotificationManager.warning(
            //     res.list.errorData.error,
            //     "Oops!",
            //     3000,
            //     null,
            //     null,
            //     ""
            // );
            return res;
        case GET:
            res = {...state};
            res.get = {...GET_INITIAL_STATE};
            res.get.loading = true;
            return res;
        case GET_SUCCESS:
            res = {...state};
            res.get = {...GET_INITIAL_STATE};
            res.get.data = payload.data;
            return res;
        case GET_ERROR:
            res = {...state};
            res.get = {...GET_INITIAL_STATE};
            res.get.errorData = payload;
            // NotificationManager.warning(
            //     res.get.errorData.error,
            //     "Oops!",
            //     3000,
            //     null,
            //     null,
            //     ""
            // );
            return res;

        default:
            return {...state};
    }
};
