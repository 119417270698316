import IPagination from "../models/IPagination";

export const ERROR_INITIAL_STATE = {
    title: "Oops!",
    error: ""
};

export const LOGIN_INITIAL_STATE = {
    loading: false,
    errorData: ERROR_INITIAL_STATE,
    token: null
};

export const REGISTER_INITIAL_STATE = {
    loading: false,
    errorData: ERROR_INITIAL_STATE,
    token: null
};

export const SET_REDIRECT_UNAUTHORIZED_INITIAL_STATE = {
    shouldRedirectUnauthorized: false
};

export const POST_INITIAL_STATE = {
    loading: false,
    errorData: ERROR_INITIAL_STATE,
    data: {}
};

export const PUT_INITIAL_STATE = {
    loading: false,
    errorData: ERROR_INITIAL_STATE,
    data: {}
};

interface ILIST_INITIAL_STATE {
    loading: boolean;
    errorData: typeof ERROR_INITIAL_STATE;
    results: any[];
    informationPage: IPagination;
}

export const LIST_INITIAL_STATE: ILIST_INITIAL_STATE = {
    loading: false,
    errorData: ERROR_INITIAL_STATE,
    results: [],
    informationPage: {
        totalItems: 0,
        totalPages: 0,
        pageSize: 12,
        currentPage: 1
    }
};

interface IGET_INITIAL_STATE {
    loading: boolean;
    errorData: typeof ERROR_INITIAL_STATE;
    data: any;
}

export const GET_INITIAL_STATE: IGET_INITIAL_STATE = {
    loading: false,
    errorData: ERROR_INITIAL_STATE,
    data: {}
};
export const GET_CONTRACTS_GROUP_BY_STATUS_INITIAL_STATE = {
    loading: false,
    errorData: ERROR_INITIAL_STATE,
    groupByStatus: {
        CREATED: {
            count: 0
        },
        COMPLETED: {
            count: 0
        },
        REJECTED: {
            count: 0
        },
        EXPIRED: {
            count: 0
        }
    }
};

export const GET_CONTRACTS_BALANCE_INITIAL_STATE = {
    loading: false,
    errorData: ERROR_INITIAL_STATE,
    contractsBalance: {
        LAST_MONTH: {
            date: "",
            count: 0,
            totalAmount: 0,
            totalManagerAmount: 0,
            totalCelebrityAmount: 0,
            totalFamososAmount: 0
        },
        THIS_MONTH: {
            date: "",
            count: 0,
            totalAmount: 0,
            totalManagerAmount: 0,
            totalCelebrityAmount: 0,
            totalFamososAmount: 0
        },
        HISTORIC: {
            date: "",
            count: 0,
            totalAmount: 0,
            totalManagerAmount: 0,
            totalCelebrityAmount: 0,
            totalFamososAmount: 0
        }
    }
};

export const DELETE_ONE_INITIAL_STATE = {
    loading: false,
    errorData: ERROR_INITIAL_STATE,
    data: {}
};

export const NOT_PAGINATED_LIST_INITIAL_STATE = {
    loading: false,
    errorData: ERROR_INITIAL_STATE,
    results: []
};

export const CREATE_INITIAL_STATE = {
    loading: false,
    errorData: ERROR_INITIAL_STATE
};

export const LINK_CELEBRITIES_INITIAL_STATE = {
    loading: false,
    errorData: ERROR_INITIAL_STATE
};

export const CONTRACT_VIDEO_UPLOAD_INITIAL_STATE = {
    isUploading: false,
    errorData: ERROR_INITIAL_STATE,
    data: {}
};

export const CONTRACT_REJECT_INITIAL_STATE = {
    isLoading: false,
    errorData: ERROR_INITIAL_STATE,
    data: {}
};
