export const ROOT_PATH = "/";

//####################################
// DASHBOARDS
export const DASHBOARD_ROOT_PATH = `/dashboards`;
export const DASHBOARD_CELEBRITIES_PATH = `${DASHBOARD_ROOT_PATH}/celebrities`;
export const DASHBOARD_CONTRACTS_PATH = `${DASHBOARD_ROOT_PATH}/famosos-contracts`;
export const DASHBOARD_BUSINESS_CONTRACTS_PATH = `${DASHBOARD_ROOT_PATH}/business-contracts`;
// END DASHBOARDS
//####################################

//####################################
// CELEBRITIES
export const CELEBRITIES_ROOT_PATH = `/celebrities`;
export const CELEBRITIES_LIST_PATH = `${CELEBRITIES_ROOT_PATH}/list`;
export const CELEBRITIES_EDITOR_PATH = `${CELEBRITIES_ROOT_PATH}/:id/editor`;
export const CELEBRITIES_EDITOR_FORM_PATH = `${CELEBRITIES_ROOT_PATH}/:id/editor/:form`;
export const CELEBRITIES_CREATOR_PATH = `${CELEBRITIES_ROOT_PATH}/creator`;
export const CELEBRITIES_DETAILS_PATH = `${CELEBRITIES_ROOT_PATH}/:id/details`;
export const CELEBRITIES_EXPERIENCES_ROOT_PATH = `${CELEBRITIES_ROOT_PATH}/:id/experiences`;
export const CELEBRITIES_EXPERIENCES_CREATOR_PATH = `${CELEBRITIES_EXPERIENCES_ROOT_PATH}/creator`;
export const CELEBRITIES_EXPERIENCES_EDITOR_PATH = `${CELEBRITIES_EXPERIENCES_ROOT_PATH}/:experienceId/editor`;
// END CELEBRITIES
//####################################

//####################################
// CELEBRITY CATEGORIES
export const CELEBRITIES_CATEGORIES_ROOT_PATH = `${CELEBRITIES_ROOT_PATH}/categories`;
export const CELEBRITIES_CATEGORIES_LIST_PATH = `${CELEBRITIES_CATEGORIES_ROOT_PATH}/list`;
export const CELEBRITIES_CATEGORIES_CREATOR_PATH = `${CELEBRITIES_CATEGORIES_ROOT_PATH}/creator`;
export const CELEBRITIES_CATEGORIES_EDITOR_PATH = `${CELEBRITIES_CATEGORIES_ROOT_PATH}/:id/editor`;
// END CELEBRITY CATEGORIES
//####################################

//####################################
// CELEBRITY CATEGORIES
export const CELEBRITIES_SECTIONS_ROOT_PATH = `/celebrities-sections`;
export const CELEBRITIES_SECTIONS_LIST_PATH = `${CELEBRITIES_SECTIONS_ROOT_PATH}/list`;
export const CELEBRITIES_SECTIONS_DETAILS_PATH = `${CELEBRITIES_SECTIONS_ROOT_PATH}/:id/details`;
export const CELEBRITIES_SECTIONS_CREATOR_PATH = `${CELEBRITIES_SECTIONS_ROOT_PATH}/creator`;
export const CELEBRITIES_SECTIONS_EDITOR_PATH = `${CELEBRITIES_SECTIONS_ROOT_PATH}/:id/editor`;
export const CELEBRITIES_SECTIONS_EDITOR_DETAILS_PATH = `${CELEBRITIES_SECTIONS_EDITOR_PATH}/data`;
export const CELEBRITIES_SECTIONS_EDITOR_CELEBRITIES_PATH = `${CELEBRITIES_SECTIONS_EDITOR_PATH}/celebrities`;
export const CELEBRITIES_SECTIONS_UPDATE_POSITIONS_PATH = `${CELEBRITIES_SECTIONS_ROOT_PATH}/update-positions`;
// END CELEBRITY CATEGORIES
//####################################

//####################################
// CELEBRITY CATEGORIES
export const LANDINGS_ROOT_PATH = `/landings`;
export const LANDINGS_LIST_PATH = `${LANDINGS_ROOT_PATH}/list`;
export const LANDINGS_DETAILS_PATH = `${LANDINGS_ROOT_PATH}/:id/details`;
export const LANDINGS_CREATOR_PATH = `${LANDINGS_ROOT_PATH}/creator`;
export const LANDINGS_EDITOR_PATH = `${LANDINGS_ROOT_PATH}/:id/editor`;
export const LANDINGS_EDITOR_DETAILS_PATH = `${LANDINGS_EDITOR_PATH}/data`;
export const LANDINGS_EDITOR_SECTIONS_PATH = `${LANDINGS_EDITOR_PATH}/sections`;
// export const LANDINGS_UPDATE_POSITIONS_PATH = `${LANDINGS_ROOT_PATH}/update-positions`;
// END CELEBRITY CATEGORIES
//####################################

//####################################
// CELEBRITY SOCIAL NETWORKS
export const CELEBRITY_SOCIAL_NETWORKS_ROOT_PATH = `celebrity-social-networks`;
export const CELEBRITY_SOCIAL_NETWORKS_LIST_PATH = `${CELEBRITY_SOCIAL_NETWORKS_ROOT_PATH}/list`;
export const CELEBRITY_SOCIAL_NETWORKS_CREATOR_PATH = `${CELEBRITY_SOCIAL_NETWORKS_ROOT_PATH}/creator`;
export const CELEBRITY_SOCIAL_NETWORKS_EDITOR_PATH = `${CELEBRITY_SOCIAL_NETWORKS_ROOT_PATH}/:id/editor`;
// END CELEBRITY SOCIAL NETWORKS
//####################################

//####################################
// LINKS
export const LINKS_ROOT_PATH = `links`;
export const LINKS_LIST_PATH = `${LINKS_ROOT_PATH}/list`;
export const LINKS_CREATOR_PATH = `${LINKS_ROOT_PATH}/creator`;
export const LINKS_EDITOR_PATH = `${LINKS_ROOT_PATH}/:id/editor`;
export const LINKS_DETAILS_PATH = `${LINKS_ROOT_PATH}/:id/details`;
// END LINKS
//####################################

//####################################
// CONTRACTS
export const CONTRACTS_ROOT_PATH = `/contracts`;
// users
export const CONTRACTS_USERS_ROOT_PATH = `${CONTRACTS_ROOT_PATH}/users`;
export const CONTRACTS_LIST_USERS_PATH = `${CONTRACTS_USERS_ROOT_PATH}/list`;
export const CONTRACTS_LIST_USERS_REJECT_PATH = `${CONTRACTS_USERS_ROOT_PATH}/reject`;
export const CONTRACTS_LIST_USERS_REJECT_FINAL_PATH = `${CONTRACTS_USERS_ROOT_PATH}/reject-final`;
export const CONTRACTS_LIST_FRAUD_VALIDATIONS_PATH = `${CONTRACTS_USERS_ROOT_PATH}/fraud-validations`;
export const CONTRACTS_DETAILS_USERS_PATH = `${CONTRACTS_USERS_ROOT_PATH}/:id/details`;
export const CONTRACTS_EDITOR_USERS_PATH = `${CONTRACTS_USERS_ROOT_PATH}/:id/editor`;
// end users

// END CONTRACTS
//####################################

//####################################
// CELEBRITY TRANSACTIONS
export const CELEBRITY_TRANSACTIONS_ROOT_PATH = `/celebrity-transactions`;
export const CELEBRITY_TRANSACTIONS_LIST_PATH = `${CELEBRITY_TRANSACTIONS_ROOT_PATH}/list`;
export const CELEBRITY_TRANSACTIONS_CREATOR_PATH = `${CELEBRITY_TRANSACTIONS_ROOT_PATH}/:id/creator`;
export const CELEBRITY_TRANSACTIONS_DETAILS_PATH = `${CELEBRITY_TRANSACTIONS_ROOT_PATH}/:id/details`;
export const CELEBRITY_TRANSACTIONS_EDITOR_PATH = `${CELEBRITY_TRANSACTIONS_ROOT_PATH}/:id/editor`;

//####################################
// CELEBRITY CATEGORIES
export const CELEBRITY_CATEGORIES_ROOT_PATH = `/celebrity-categories`;
export const CELEBRITY_CATEGORIES_LIST_PATH = `${CELEBRITY_CATEGORIES_ROOT_PATH}/list`;
export const CELEBRITY_CATEGORIES_CREATOR_PATH = `${CELEBRITY_CATEGORIES_ROOT_PATH}/:id/creator`;
export const CELEBRITY_CATEGORIES_DETAILS_PATH = `${CELEBRITY_CATEGORIES_ROOT_PATH}/:id/details`;
export const CELEBRITY_CATEGORIES_EDITOR_PATH = `${CELEBRITY_CATEGORIES_ROOT_PATH}/:id/editor`;
//####################################
// CELEBRITY CATEGORIES - CELEBRITIES
export const CELEBRITY_CATEGORIES_CELEBRITIES_ROOT_PATH = `/celebrity-categories/celebrities/:id`;

//####################################
// USERS
export const USERS_ROOT_PATH = `/users`;
export const USERS_LIST_PATH = `${USERS_ROOT_PATH}/list`;
export const USERS_CREATOR_PATH = `${USERS_ROOT_PATH}/creator`;
export const USERS_DETAILS_PATH = `${USERS_ROOT_PATH}/:id/details`;
export const USERS_EDITOR_PATH = `${USERS_ROOT_PATH}/:id/editor`;

// END USERS
//####################################

//####################################
// MANAGER
export const MANAGERS_ROOT_PATH = `/managers`;
export const MANAGERS_LIST_PATH = `${MANAGERS_ROOT_PATH}/list`;
export const MANAGERS_CREATOR_PATH = `${MANAGERS_ROOT_PATH}/creator`;
export const MANAGERS_EDITOR_PATH = `${MANAGERS_ROOT_PATH}/:id/editor`;
export const MANAGERS_EDITOR_DETAILS_PATH = `${MANAGERS_EDITOR_PATH}/details`;
export const MANAGERS_EDITOR_CELEBRITIES_PATH = `${MANAGERS_EDITOR_PATH}/celebrities`;
export const MANAGERS_DETAILS_PATH = `${MANAGERS_ROOT_PATH}/:id/details`;

// END MANAGER
//####################################

//####################################
// CELEBRITY PAYMENT

export const MANAGERS_PAYMENTS_ROOT_PATH = `/managers-payments`;
export const MANAGERS_PAYMENTS_LIST_PATH = `${MANAGERS_PAYMENTS_ROOT_PATH}/list`;
export const MANAGERS_PAYMENTS_CREATOR_PATH = `${MANAGERS_PAYMENTS_ROOT_PATH}/creator`;
export const MANAGERS_PAYMENTS_DETAILS_PATH = `${MANAGERS_PAYMENTS_ROOT_PATH}/:id/details`;
export const MANAGERS_PAYMENTS_EDITOR_PATH = `${MANAGERS_PAYMENTS_ROOT_PATH}/:id/editor`;

// END CELEBRITY PAYMENT
//####################################

//####################################
// COUPONS

export const COUPONS_ROOT_PATH = `/coupons`;
export const COUPONS_LIST_PATH = `${COUPONS_ROOT_PATH}/list`;
export const COUPONS_DETAILS_PATH = `${COUPONS_ROOT_PATH}/:id/details`;
export const COUPONS_CREATOR_PATH = `${COUPONS_ROOT_PATH}/creator`;
export const COUPONS_EDITOR_PATH = `${COUPONS_ROOT_PATH}/:id/editor`;

// END COUPONS
//####################################

//####################################
// COUPONS

export const BUSINESS_CONTRACTS_ROOT_PATH = `/business_contracts`;
export const BUSINESS_CONTRACTS_LIST_PATH = `${BUSINESS_CONTRACTS_ROOT_PATH}/list`;
export const BUSINESS_CONTRACTS_DETAILS_PATH = `${BUSINESS_CONTRACTS_ROOT_PATH}/:id/details`;
export const BUSINESS_CONTRACTS_CREATOR_PATH = `${BUSINESS_CONTRACTS_ROOT_PATH}/creator`;
export const BUSINESS_CONTRACTS_EDITOR_PATH = `${BUSINESS_CONTRACTS_ROOT_PATH}/:id/editor`;

//####################################
// NOTIFICATIONS

export const NOTIFICATIONS_ROOT_PATH = `/notifications`;
export const PUSH_NOTIFICATIONS_ROOT_PATH = `/notifications/push`;
export const PUSH_NOTIFICATIONS_CREATE_PATH = `/notifications/push/create`;
export const PUSH_NOTIFICATIONS_DETAILS_PATH = `/notifications/push/:id/details`;
export const PUSH_NOTIFICATIONS_LIST_PATH = `/notifications/push/list`;

//####################################
// PAYMENTS LINKS

export const PAYMENTS_LINKS_ROOT_PATH = `/payments-links`;
export const PAYMENTS_LINKS_CREATE_PATH = `${PAYMENTS_LINKS_ROOT_PATH}/list`;

//####################################
// NOTIFICATIONS

export const ADVANCE_PAYMENTS_ROOT_PATH = `/advance-payments`;
export const ADVANCE_PAYMENTS_LIST_PATH = `${ADVANCE_PAYMENTS_ROOT_PATH}/list`;
export const ADVANCE_PAYMENTS_DETAILS_PATH = `${ADVANCE_PAYMENTS_ROOT_PATH}/:id/details`;
export const ADVANCE_PAYMENTS_CREATOR_PATH = `${ADVANCE_PAYMENTS_ROOT_PATH}/:id/creator`;
export const ADVANCE_PAYMENTS_SELECT_CELEBRITY = `${ADVANCE_PAYMENTS_ROOT_PATH}/select`;
export const ADVANCE_PAYMENTS_EDITOR_PATH = `${ADVANCE_PAYMENTS_ROOT_PATH}/:id/editor`;

// END COUPONS
//####################################

export const APP_ROOT_PATH = "/celebrities";

//AUTHENTICATION ROUTES
export const AUTHENTICATION_ROOT_PATH = "/authentication";
export const AUTHENTICATION_LOGIN_PATH = `${AUTHENTICATION_ROOT_PATH}/login`;
export const AUTHENTICATION_TOKEN_RECEIVER_PATH = `${AUTHENTICATION_ROOT_PATH}/token-receiver`;

//ERRORS APP
export const ERROR_ROOT_PATH = "/error";

//ERROR APP NAVIGATOR
export const ERROR_ROOT_NAVIGATOR_PATH = "/error_navigator";
