export const MODEL_PATH = "contracts";
export const MODEL_REACTION= "contract-reactions";

//##############################################################
// CRUD
export const POST = MODEL_PATH + "_POST";
export const POST_SUCCESS = MODEL_PATH + "_POST_SUCCESS";
export const POST_ERROR = MODEL_PATH + "_POST_ERROR";
export const LIST = MODEL_PATH + "_LIST";
export const LIST_SUCCESS = MODEL_PATH + "_LIST_SUCCESS";
export const LIST_ERROR = MODEL_PATH + "_LIST_ERROR";
export const GET = MODEL_PATH + "_GET";
export const GET_SUCCESS = MODEL_PATH + "_GET_SUCCESS";
export const GET_ERROR = MODEL_PATH + "_GET_ERROR";
export const PUT = MODEL_PATH + "_PUT";
export const PUT_SUCCESS = MODEL_PATH + "_PUT_SUCCESS";
export const PUT_ERROR = MODEL_PATH + "_PUT_ERROR";
export const DELETE_ONE = MODEL_PATH + "_DELETE_ONE";
export const DELETE_ONE_SUCCESS = MODEL_PATH + "_DELETE_ONE_SUCCESS";
export const DELETE_ONE_ERROR = MODEL_PATH + "_DELETE_ONE_ERROR";
// END CRUD
//##############################################################
export const SET_VIDEO_UPLOAD_PROGRESS =
    MODEL_PATH + "_SET_VIDEO_UPLOAD_PROGRESS";
export const START_CONTRACT_VIDEO_UPLOAD =
    MODEL_PATH + "_START_CONTRACT_VIDEO_UPLOAD";
export const ACCEPT_CONTRACT = MODEL_PATH + "_ACCEPT_CONTRACT";
export const ACCEPT_CONTRACT_SUCCESS = MODEL_PATH + "_ACCEPT_CONTRACT_SUCCESS";
export const ACCEPT_CONTRACT_ERROR = MODEL_PATH + "_ACCEPT_CONTRACT_ERROR";
export const RESTART_CONTRACT_VIDEO_UPLOAD =
    MODEL_PATH + "_RESTART_CONTRACT_VIDEO_UPLOAD";
export const REJECT_CONTRACT = MODEL_PATH + "_REJECT_CONTRACT";
export const RESET_REJECT_CONTRACT = MODEL_PATH + "_RESET_REJECT_CONTRACT";
export const REJECT_CONTRACT_SUCCESS = MODEL_PATH + "_REJECT_CONTRACT_SUCCESS";
export const REJECT_CONTRACT_ERROR = MODEL_PATH + "_REJECT_CONTRACT_ERROR";
