import { MODEL_PATH } from "./constants";

//##############################################################
// CRUD
export const CRUD_LIST_PATH = "crud/list/" + MODEL_PATH;
export const CRUD_POST_PATH = "crud/post/" + MODEL_PATH;
export const CRUD_GET_PATH = "crud/get/" + MODEL_PATH + "/";
export const CRUD_PUT_PATH = "crud/put/" + MODEL_PATH + "/";
export const CRUD_DELETE_PATH = "crud/delete/" + MODEL_PATH + "/";
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom paths
export const LIST_PATH = `custom-endpoints/${MODEL_PATH}/list`;
export const NOT_PAGINATED_LIST_PATH = "crud/not-paginated-list/" + MODEL_PATH;
export const CREATE_PATH = "custom-endpoints/" + MODEL_PATH + "/create";
export const UPDATE_POSITIONS_PATH = `custom-endpoints/${MODEL_PATH}/update-positions`;
export const UPDATE_PATH = `custom-endpoints/${MODEL_PATH}/update`;
export const GET_CELEBRITY_SECTION_DATA_PATH = `custom-endpoints/${MODEL_PATH}/get/`;
export const DELETE_CELEBRITY_SECTION_PATH = `custom-endpoints/${MODEL_PATH}/delete/`;
// CUSTOM ACTIONS
// ##############################################################
