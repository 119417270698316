export const MODEL_PATH= 'contract-addons';

//##############################################################
// CRUD
export const POST = MODEL_PATH + "_POST";
export const POST_SUCCESS = MODEL_PATH + "_POST_SUCCESS";
export const POST_ERROR = MODEL_PATH + "_POST_ERROR";
export const LIST = MODEL_PATH + "_LIST";
export const LIST_SUCCESS = MODEL_PATH + "_LIST_SUCCESS";
export const LIST_ERROR = MODEL_PATH + "_LIST_ERROR";
export const GET = MODEL_PATH + "_GET";
export const GET_SUCCESS = MODEL_PATH + "_GET_SUCCESS";
export const GET_ERROR = MODEL_PATH + "_GET_ERROR";
export const PUT = MODEL_PATH + "_PUT";
export const PUT_SUCCESS = MODEL_PATH + "_PUT_SUCCESS";
export const PUT_ERROR = MODEL_PATH + "_PUT_ERROR";
export const DELETE_ONE = MODEL_PATH + "_DELETE_ONE";
export const DELETE_ONE_SUCCESS = MODEL_PATH + "_DELETE_ONE_SUCCESS";
export const DELETE_ONE_ERROR = MODEL_PATH + "_DELETE_ONE_ERROR";

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom action constants
export const GET_BY_ID_ADD_ONS= MODEL_PATH + "_GET_BY_ID_ADD_ONS";
export const GET_BY_ID_ADD_ONS_SUCCESS = MODEL_PATH + "_GET_BY_ID_ADD_ONS_SUCCESS";
export const GET_BY_ID_ADD_ONS_ERROR = MODEL_PATH + "_GET_BY_ID_ADD_ONS_ERROR";

export const POST_ADD_ONS = MODEL_PATH + "_POST_ADD_ONS";
export const POST_ADD_ONS_SUCCESS = MODEL_PATH + "_POST_ADD_ONS_SUCCESS";
export const POST_ADD_ONS_ERROR =MODEL_PATH + "_POST_ADD_ONS_ERROR";

export const PUT_BY_ID_ADD_ONS = MODEL_PATH + "_PUT_ADD_ONS";
export const PUT_BY_ID_ADD_ONS_SUCCESS = MODEL_PATH + "_PUT_ADD_ONS_SUCCESS";
export const PUT_BY_ID_ADD_ONS_ERROR = MODEL_PATH + "_PUT_ADD_ONS_ERROR";
      

export const DELETE_BY_ID_ADD_ONS =
    MODEL_PATH + "_DELETE_BY_ID_ADD_ONS";

// CUSTOM ACTIONS
// ##############################################################




