import {
    CREATE_INITIAL_STATE,
    DELETE_ONE_INITIAL_STATE,
    GET_INITIAL_STATE,
    LIST_INITIAL_STATE,
    NOT_PAGINATED_LIST_INITIAL_STATE,
    POST_INITIAL_STATE,
    PUT_INITIAL_STATE
} from "../initial_states";
import {NotificationManager} from "../../components/common/react-notifications";
import {
    CREATE,
    CREATE_ERROR,
    CREATE_SUCCESS,
    DELETE_CELEBRITY_SECTION,
    DELETE_CELEBRITY_SECTION_ERROR,
    DELETE_CELEBRITY_SECTION_SUCCESS,
    DELETE_ONE,
    DELETE_ONE_ERROR,
    DELETE_ONE_SUCCESS,
    GET,
    GET_CELEBRITY_SECTION_DATA,
    GET_CELEBRITY_SECTION_DATA_ERROR,
    GET_CELEBRITY_SECTION_DATA_SUCCESS,
    GET_ERROR,
    GET_SUCCESS,
    LIST,
    LIST_ERROR,
    LIST_SUCCESS,
    NOT_PAGINATED_LIST,
    NOT_PAGINATED_LIST_ERROR,
    NOT_PAGINATED_LIST_SUCCESS,
    POST,
    POST_ERROR,
    POST_SUCCESS,
    PUT,
    PUT_ERROR,
    PUT_SUCCESS,
    RESET_CREATE_ERROR,
    RESET_UPDATE_ERROR,
    UPDATE,
    UPDATE_ERROR,
    UPDATE_POSITIONS,
    UPDATE_POSITIONS_ERROR,
    UPDATE_POSITIONS_SUCCESS,
    UPDATE_SUCCESS
} from "./constants";

const INIT_STATE = {
    // CRUD
    post: POST_INITIAL_STATE,
    put: PUT_INITIAL_STATE,
    list: LIST_INITIAL_STATE,
    get: GET_INITIAL_STATE,
    deleteOne: DELETE_ONE_INITIAL_STATE,
    notPaginatedList: NOT_PAGINATED_LIST_INITIAL_STATE,
    updatePositions: PUT_INITIAL_STATE,
    // CUSTOM ACTIONS
    create: CREATE_INITIAL_STATE,
    update: PUT_INITIAL_STATE,
    getCelebritySectionData: GET_INITIAL_STATE,
    deleteCelebritySection: DELETE_ONE_INITIAL_STATE
};

export default (state = INIT_STATE, action) => {
    let res;
    switch (action.type) {
        //##############################################################
        // CRUD
        case POST:
            res = {...state};
            res.post = {...POST_INITIAL_STATE};
            res.post.loading = true;
            return res;
        case POST_SUCCESS:
            res = {...state};
            res.post = {...POST_INITIAL_STATE};
            res.post.data = action.payload.data;
            return res;
        case POST_ERROR:
            res = {...state};
            res.post = {...POST_INITIAL_STATE};
            res.post.errorData = action.payload;
            NotificationManager.warning(
                res.post.errorData.error + " in " + POST_ERROR,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        case PUT:
            res = {...state};
            res.put = {...PUT_INITIAL_STATE};
            res.put.loading = true;
            return res;
        case PUT_SUCCESS:
            res = {...state};
            res.put = {...PUT_INITIAL_STATE};
            res.put.data = action.payload.data;
            return res;
        case PUT_ERROR:
            res = {...state};
            res.put = {...PUT_INITIAL_STATE};
            res.put.errorData = action.payload;
            NotificationManager.warning(
                res.put.errorData.error + " in " + PUT_ERROR,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        case LIST:
            res = {...state};
            res.list = {...LIST_INITIAL_STATE};
            res.list.loading = true;
            return res;
        case LIST_SUCCESS:
            res = {...state};
            res.list = {...LIST_INITIAL_STATE};
            res.list.results = action.payload.results;
            res.list.informationPage = action.payload.informationPage;
            return res;
        case LIST_ERROR:
            res = {...state};
            res.list = {...LIST_INITIAL_STATE};
            res.list.errorData = action.payload;
            NotificationManager.warning(
                res.list.errorData.error + " in " + LIST_ERROR,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        case GET:
            res = {...state};
            res.get = {...GET_INITIAL_STATE};
            res.get.loading = true;
            return res;
        case GET_SUCCESS:
            res = {...state};
            res.get = {...GET_INITIAL_STATE};
            res.get.data = action.payload.data;
            return res;
        case GET_ERROR:
            res = {...state};
            res.get = {...GET_INITIAL_STATE};
            res.get.errorData = action.payload;
            NotificationManager.warning(
                res.get.errorData.error + " in " + GET_ERROR,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        case DELETE_ONE:
            res = {...state};
            res.delete = {...DELETE_ONE_INITIAL_STATE};
            res.delete.loading = true;
            return res;
        case DELETE_ONE_SUCCESS:
            res = {...state};
            res.delete = {...DELETE_ONE_INITIAL_STATE};
            res.delete.data = action.payload.data;
            return res;
        case DELETE_ONE_ERROR:
            res = {...state};
            res.delete = {...DELETE_ONE_INITIAL_STATE};
            res.delete.errorData = action.payload;
            NotificationManager.warning(
                res.delete.errorData.error + " in " + DELETE_ONE_ERROR,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        // CRUD
        //##############################################################

        //##############################################################
        // CUSTOM ACTIONS
        // TODO: Write here your custom cases
        case CREATE:
            res = {...state};
            res.create = {...CREATE_INITIAL_STATE};
            res.create.loading = true;
            return res;
        case CREATE_SUCCESS:
            res = {...state};
            res.create = {...CREATE_INITIAL_STATE};
            return res;
        case CREATE_ERROR:
            res = {...state};
            res.create = {...CREATE_INITIAL_STATE};
            res.create.errorData = action.payload;
            return res;
        case NOT_PAGINATED_LIST:
            res = {...state};
            res.notPaginatedList = {
                ...NOT_PAGINATED_LIST_INITIAL_STATE,
                loading: true
            };
            return res;
        case NOT_PAGINATED_LIST_SUCCESS:
            res = {...state};
            res.notPaginatedList = {
                ...NOT_PAGINATED_LIST_INITIAL_STATE,
                loading: false,
                results: action.payload.results
            };
            return res;
        case NOT_PAGINATED_LIST_ERROR:
            res = {...state};
            res.notPaginatedList = {
                ...NOT_PAGINATED_LIST_INITIAL_STATE,
                errorData: action.payload
            };
            return res;
        case UPDATE_POSITIONS:
            res = {...state};
            res.updatePositions = {
                ...PUT_INITIAL_STATE,
                loading: true
            };
            return res;
        case UPDATE_POSITIONS_SUCCESS:
            res = {...state};
            res.updatePositions = {
                ...PUT_INITIAL_STATE,
                loading: false,
                data: action.payload
            };
            return res;
        case UPDATE_POSITIONS_ERROR:
            res = {...state};
            res.updatePositions = {
                ...PUT_INITIAL_STATE,
                errorData: action.payload
            };
            return res;

        case UPDATE:
            res = {...state};
            res.update = {
                ...PUT_INITIAL_STATE,
                loading: true
            };
            return res;
        case UPDATE_SUCCESS:
            res = {...state};
            res.update = {
                ...PUT_INITIAL_STATE,
                loading: false,
                data: action.payload
            };
            return res;
        case UPDATE_ERROR:
            res = {...state};
            res.update = {
                ...PUT_INITIAL_STATE,
                errorData: action.payload
            };
            return res;
        case GET_CELEBRITY_SECTION_DATA:
            res = {...state};
            res.getCelebritySectionData = {...GET_INITIAL_STATE};
            res.getCelebritySectionData.loading = true;
            return res;
        case GET_CELEBRITY_SECTION_DATA_SUCCESS:
            res = {...state};
            res.getCelebritySectionData = {...GET_INITIAL_STATE};
            res.getCelebritySectionData.data = action.payload.data;
            return res;
        case GET_CELEBRITY_SECTION_DATA_ERROR:
            res = {...state};
            res.getCelebritySectionData = {...GET_INITIAL_STATE};
            res.getCelebritySectionData.errorData = action.payload;
            return res;

        case DELETE_CELEBRITY_SECTION:
            res = {...state};
            res.deleteCelebritySection = {
                ...DELETE_ONE_INITIAL_STATE,
                loading: true
            };
            return res;
        case DELETE_CELEBRITY_SECTION_SUCCESS:
            res = {...state};
            res.deleteCelebritySection = {
                ...DELETE_ONE_INITIAL_STATE,
                loading: false,
                data: action.payload
            };
            return res;
        case DELETE_CELEBRITY_SECTION_ERROR:
            res = {...state};
            res.deleteCelebritySection = {
                ...DELETE_ONE_INITIAL_STATE,
                errorData: action.payload
            };
            return res;
        case RESET_CREATE_ERROR:
            res = {...state};
            res.create = {
                ...state.create,
                errorData: CREATE_INITIAL_STATE.errorData
            };
            return res;
        case RESET_UPDATE_ERROR:
            res = {...state};
            res.update = {
                ...state.update,
                errorData: PUT_INITIAL_STATE.errorData
            };
            return res;

        // CUSTOM ACTIONS
        // ##############################################################

        default:
            return {...state};
    }
};
