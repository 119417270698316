import {
    DELETE_ONE,
    DELETE_ONE_ERROR,
    DELETE_ONE_SUCCESS,
    DELETE_BY_ID_ADD_ONS,
    GET,
    GET_ERROR,
    GET_SUCCESS,
    GET_BY_ID_ADD_ONS,
    GET_BY_ID_ADD_ONS_ERROR,
    GET_BY_ID_ADD_ONS_SUCCESS,
    POST,
    POST_ERROR,
    POST_SUCCESS,
    POST_ADD_ONS,
    POST_ADD_ONS_ERROR,
    POST_ADD_ONS_SUCCESS,
    PUT,
    PUT_ERROR,
    PUT_SUCCESS,
    PUT_BY_ID_ADD_ONS,
    PUT_BY_ID_ADD_ONS_SUCCESS,
    PUT_BY_ID_ADD_ONS_ERROR,
    LIST,
    LIST_ERROR,
    LIST_SUCCESS,
} from "./constants";


//##############################################################
// CRUD
export const post = (payload) => ({
    type: POST,
    payload: payload
});

export const postSuccess = (payload) => ({
    type: POST_SUCCESS,
    payload: payload
});

export const postError = (payload) => ({
    type: POST_ERROR,
    payload: payload
});

export const put = (payload) => ({
    type: PUT,
    payload: payload
});
export const putSuccess = (payload) => ({
    type: PUT_SUCCESS,
    payload: payload
});
export const putError = (payload) => ({
    type: PUT_ERROR,
    payload: payload
});
export const list = (payload) => ({
    type: LIST,
    payload
});
export const listSuccess = (payload) => ({
    type: LIST_SUCCESS,
    payload
});
export const listError = (payload) => ({
    type: LIST_ERROR,
    payload
});
export const get = (payload) => ({
    type: GET,
    payload
});
export const getSuccess = (payload) => ({
    type: GET_SUCCESS,
    payload: payload
});
export const getError = (payload) => ({
    type: GET_ERROR,
    payload: payload
});
export const deleteOne = ( payload) => ({
    type: DELETE_ONE,
    payload
});
export const deleteOneSuccess = (  payload ) => ({
    type: DELETE_ONE_SUCCESS,
    payload
});
export const deleteOneError = ( payload) => ({
    type: DELETE_ONE_ERROR,
    payload
});
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom actions
export const getCelebrityAddOnsByID = (id )=> ({
    type: GET_BY_ID_ADD_ONS,
    payload: id
});
export const getCelebrityAddOnsByIdSuccess = (payload ) => ({
    type: GET_BY_ID_ADD_ONS_SUCCESS,
    payload
});
export const getCelebrityAddOnsByIdError = (payload ) => ({
    type: GET_BY_ID_ADD_ONS_ERROR,
    payload
});
export const postCelebrityAddOns = ( payload  ) => ({
    type: POST_ADD_ONS,
    payload
});
export const postCelebrityAddOnsSuccess = (  payload ) => ({
    type: POST_ADD_ONS_SUCCESS,
    payload
});
export const postCelebrityAddOnsError = (  payload ) => ({
    type: POST_ADD_ONS_ERROR,
    payload
});
export const putCelebrityAddOnsById = ({id, data}) => ({
    type: PUT_BY_ID_ADD_ONS,
    payload: {id, data }
});
export const putCelebrityAddOnsByIdSuccess = ({id, data} ) => ({
    type: PUT_BY_ID_ADD_ONS_SUCCESS,
    payload: {id, data }
});
export const putCelebrityAddOnsByIdError = ({id, data} ) => ({
    type: PUT_BY_ID_ADD_ONS_ERROR,
    payload: {id, data }
});
export const deleteCelebrityAddOnsByID = (  payload ) => ({
    type: DELETE_BY_ID_ADD_ONS,
    payload
});

// CUSTOM ACTIONS
// ##############################################################
