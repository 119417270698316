import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authentication from "./authentication/reducer";
import celebrities from "./celebrities/reducer";
import countries from "./countries/reducer";
import managers from "./managers/reducer";
import celebrityCategories from "./celebrity-categories/reducer";
import celebrityContractTypes from "./celebrity-contract-types/reducer";
import celebritySocialNetworks from "./celebrity-social-networks/reducer";
import contractAgreements from "./contract-agreements/reducer";
import celebritiesSections from "./celebrity-sections/reducer";
import contracts from "./contracts/reducer";
import users from "./users/reducer";
import discountCoupons from "./discount-coupons/reducer";
import celebrityReferrals from "./celebrity-referrals/reducer";
import userPayments from "./user-payments/reducer";
import businessContracts from "./business-contracts/reducer";
import managerAgreements from "./manager-agreements/reducer";
import landings from "./landings/reducer";
import notificationLogs from "./notification-logs/reducer";
import celebrityNotes from "./celebrity-notes/reducer";
import links from "./links/reducer";
import notifications from "./notifications/reducer";
import advancePayments from "./advance-payment/reducer";
import celebrityTransactions from "./celebrity-transactions/reducer";
import celebrityAddOns from "./celebrity-add-ons/reducer";
import contractAddOns from "./contract-add-ons/reducer";

const reducers = combineReducers({
  landings,
  notificationLogs,
  menu,
  settings,
  authentication,
  celebrities,
  managers,
  countries,
  celebrityCategories,
  celebrityContractTypes,
  celebritySocialNetworks,
  contractAgreements,
  celebritiesSections,
  contracts,
  users,
  discountCoupons,
  celebrityReferrals,
  userPayments,
  businessContracts,
  managerAgreements,
  links,
  notifications,
  celebrityNotes,
  advancePayments,
  celebrityTransactions,
  celebrityAddOns,
  contractAddOns,
});

export default reducers;

// Infer the `RootState` types from the combinedReducers itself
export type RootState = ReturnType<typeof reducers>;
