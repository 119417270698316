import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import apiService from "../api_service";
import {
  CELEBRITY_SEARCH_LIST,
  DELETE_ONE,
  GET,
  GET_BY_USERNAME,
  GET_CELEBRITY_DETAILS,
  GET_CONTRACTS_BALANCE,
  GET_CONTRACTS_GROUP_BY_STATUS,
  GET_REFERRAL_OR_MANAGER,
  LIST,
  POST,
  PUT,
  REJECT_CELEBRITY,
} from "./constants";
import {
  celebritySearchListError,
  celebritySearchListSuccess,
  deleteOneCelebrityById,
  deleteOneError,
  deleteOneSuccess,
  getByUsernameError,
  getByUsernameSuccess,
  getCelebrityDetailsError,
  getCelebrityDetailsSuccess,
  getContractsBalanceError,
  getContractsBalanceSuccess,
  getContractsGroupByStatusError,
  getContractsGroupByStatusSuccess,
  getError,
  getSuccess,
  listError,
  listSuccess,
  postError,
  postSuccess,
  putError,
  putSuccess,
  rejectCelebrityError,
  rejectCelebritySuccess,
} from "./actions";
import {
  CELEBRITY_SEARCH_LIST_PATH,
  CRUD_DELETE_PATH,
  CRUD_GET_PATH,
  CRUD_LIST_PATH,
  CRUD_POST_PATH,
  CUSTOM_ENDPOINTS_SAVE,
  CUSTOM_ENDPOINTS_UPDATE,
  GET_BY_USERNAME_PATH,
  GET_CELEBRITY_DETAILS_PATH,
  GET_CONTRACTS_BALANCE_PATH,
  GET_CONTRACTS_GROUP_BY_STATUS_PATH,
  GET_REFERRAL_OR_MANAGER_PATH,
  REJECT_CELEBRITY_PATH,
} from "./paths";

import { CELEBRITIES_EDITOR_PATH } from "../../routing/paths";

import { NotificationManager } from "../../components/common/react-notifications";

//#######################
// LIST
export function* watchList() {
  yield takeEvery(LIST, listSaga);
}

export const listSagaAsync = async (params) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: CRUD_LIST_PATH,
      params: params,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* listSaga({ payload }) {
  try {
    const response = yield call(listSagaAsync, payload);
    if (response.status === "OK") {
      yield put(listSuccess(response));
    } else {
      yield put(listError(response));
    }
  } catch (e) {
    yield put(listError(e.data ? e.data : {}));
  }
}

// END LIST
//#######################

//#######################
// GET
export function* watchGet() {
  yield takeEvery(GET, getSaga);
}

const getSagaAsync = async ({ objectID, params }) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: CRUD_GET_PATH + objectID,
      params: params,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* getSaga({ payload }) {
  try {
    const response = yield call(getSagaAsync, payload);
    if (response.status === "OK") {
      yield put(getSuccess(response));
    } else {
      yield put(getError(response));
    }
  } catch (e) {
    yield put(getError(e.data ? e.data : {}));
  }
}

// END GET
//#######################

//#######################
// POST
export function* watchPost() {
  yield takeEvery(POST, postSaga);
}

export const postCrudSagaAsync = async (body) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "POST",
      path: CRUD_POST_PATH,
      body: body,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

export const postSagaAsync = async (body) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "POST",
      path: CUSTOM_ENDPOINTS_SAVE,
      body,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* postSaga({ payload, history }) {
  try {
    const response = yield call(postSagaAsync, payload);
    if (response.status === "OK") {
      yield put(postSuccess(response));
      history.push(
        CELEBRITIES_EDITOR_PATH.replace(":id", response.data.id) + "?step=2"
      );
    } else {
      yield put(postError(response));
    }
  } catch (e) {
    yield put(postError(e.data ? e.data : {}));
  }
}

// END POST
//#######################

//#######################
// PUT
export function* watchPut() {
  yield takeEvery(PUT, putSaga);
}

export const putSagaAsync = async (body) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "PUT",
      path: CUSTOM_ENDPOINTS_UPDATE,
      body,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* putSaga({ payload }) {
  try {
    const response = yield call(putSagaAsync, payload);
    if (response.status === "OK") {
      yield put(putSuccess(response));
    } else {
      yield put(putError(response));
    }
  } catch (e) {
    yield put(putError(e.data ? e.data : {}));
  }
}

// END PUT
//#######################

//#######################
// DELETE
export function* watchDeleteOne() {
  yield takeEvery(DELETE_ONE, deleteOneSaga);
}

const deleteOneSagaAsync = async (objectId) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "DELETE",
      path: CRUD_DELETE_PATH + objectId,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* deleteOneSaga({ payload }) {
  try {
    const response = yield call(deleteOneSagaAsync, payload);
    if (response.status === "OK") {
      yield put(deleteOneSuccess(response));
    } else {
      yield put(deleteOneError(response));
    }
  } catch (e) {
    yield put(deleteOneError(e.data ? e.data : {}));
  }
}

// END DELETE
//#######################

//#######################
// GET_BY_USERNAME
export function* watchGetByusername() {
  yield takeEvery(GET_BY_USERNAME, getByUsernameSaga);
}

const getByUsernameSagaAsync = async ({ objectID, params }) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: GET_BY_USERNAME_PATH + objectID,
      params: params,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* getByUsernameSaga({ payload }) {
  const response = yield call(getByUsernameSagaAsync, payload);
  if (response.status === "OK") {
    yield put(getByUsernameSuccess(response));
  } else {
    yield put(getByUsernameError(response));
  }
}

// END GET_BY_USERNAME
//#######################

//#######################
// GET INSTAGRAM MEDIAS
export const getInstagramMediasSagaAsync = async (username) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: "https://www.instagram.com/" + username + "/?__a=1",
      custom_endpoint: true,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

export function* watchGetContractsGroupByStatus() {
  yield takeEvery(GET_CONTRACTS_GROUP_BY_STATUS, getContractsGroupByStatusSaga);
}

const getContractsGroupByStatusSagaAsync = async ({ celebrityId, params }) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: GET_CONTRACTS_GROUP_BY_STATUS_PATH + celebrityId,
      params,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* getContractsGroupByStatusSaga({ payload }) {
  const response = yield call(getContractsGroupByStatusSagaAsync, payload);
  if (response.status === "OK") {
    yield put(getContractsGroupByStatusSuccess(response));
  } else {
    NotificationManager.warning(response.data.error, "Oops!", 3000);
    yield put(getContractsGroupByStatusError(response));
  }
}

export function* watchGetContractsBalance() {
  yield takeEvery(GET_CONTRACTS_BALANCE, getContractsBalanceSaga);
}

const getContractsBalanceSagaAsync = async ({ celebrityId, params }) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: GET_CONTRACTS_BALANCE_PATH + celebrityId,
      params,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

function* getContractsBalanceSaga({ payload }) {
  const response = yield call(getContractsBalanceSagaAsync, payload);
  if (response.status === "OK") {
    yield put(getContractsBalanceSuccess(response));
  } else {
    NotificationManager.warning(response.data.error, "Oops!", 3000);
    yield put(getContractsBalanceError(response));
  }
}

// END GET INSTAGRAM MEDIAS
//#######################

//#######################
// REJECT CELEBRITY

export function* watchRejectCelebritySaga() {
  yield takeEvery(REJECT_CELEBRITY, rejectCelebrity);
}

export const rejectCelebritySagaAsync = async (body) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "PUT",
      path: REJECT_CELEBRITY_PATH,
      body,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* rejectCelebrity({ payload }) {
  const response = yield call(rejectCelebritySagaAsync, payload);
  if (response.status === "OK") {
    yield put(rejectCelebritySuccess(response));
    yield put(deleteOneCelebrityById(response.data.id));
  } else {
    NotificationManager.warning(response.data.error, "Oops!", 3000);
    yield put(rejectCelebrityError(response));
  }
}

// const object =
// {
//   "id": 1468,
//   "deletedAt": null,
//   "fullName": "Hayfa George",
//   "status": 20,
//   "securityCode": "",
//   "email": "nuconyf@mailinator.com",
//   "username": "kofujuq",
//   "description": "Earum iure minus dol",

//   "hashtags": [
//       "Repudiandae adipisci"
//   ],
//   "isDonor": true,
//   "showSimilarCelebrities": true,
//   "availableForFlashDeliveries": false,
//   "availableForSubscriptions": false,

// }
// END REJECT CELEBRITY
//#######################

// GET REFERRAL OR MANAGER
//#########################

export function* watchGetReferralOrManager() {
  yield takeEvery(GET_REFERRAL_OR_MANAGER, getReferralOrManager);
}

export const getReferralOrManagerAsync = async (celebrity_username, params) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: GET_REFERRAL_OR_MANAGER_PATH.replace(
        ":celebrity_username",
        celebrity_username
      ),
      params,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* getReferralOrManager({ payload }) {
  const response = yield call(getReferralOrManagerAsync, payload);
  if (response.status === "OK") {
    yield put(getContractsBalanceSuccess(response));
  } else {
    NotificationManager.warning(response.data.error, "Oops!", 3000);
    yield put(getContractsBalanceError(response));
  }
}

// SEARCH CELEBRITY LIST
//#########################

export function* watchCelebritySearchList() {
  yield takeEvery(CELEBRITY_SEARCH_LIST, celebritySearchList);
}

export const celebritySearchListAsync = async (params) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: CELEBRITY_SEARCH_LIST_PATH,
      params,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* celebritySearchList({ payload }) {
  const response = yield call(celebritySearchListAsync, payload);
  if (response.status === "OK") {
    console.log(response);
    yield put(celebritySearchListSuccess(response));
  } else {
    NotificationManager.warning(response.data.error, "Oops!", 3000);
    yield put(celebritySearchListError(response));
  }
}

//END GET REFERRAL OR MANAGER

//#######################
// GET CELEBRITY DETAILS
export function* watchGetCelebrityDetails() {
  yield takeEvery(GET_CELEBRITY_DETAILS, getCelebrityDetailsSaga);
}

const getCelebrityDetailsSagaAsync = async ({ objectID, params }) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: GET_CELEBRITY_DETAILS_PATH + objectID,
      params: params,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* getCelebrityDetailsSaga({ payload }) {
  try {
    const response = yield call(getCelebrityDetailsSagaAsync, payload);
    if (response.status === "OK") {
      yield put(getCelebrityDetailsSuccess(response));
    } else {
      yield put(getCelebrityDetailsError(response));
    }
  } catch (e) {
    yield put(getCelebrityDetailsError(e.data ? e.data : {}));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchGet),
    fork(watchPut),
    fork(watchPost),
    fork(watchDeleteOne),
    fork(watchGetByusername),
    fork(watchGetContractsGroupByStatus),
    fork(watchGetContractsBalance),
    fork(watchGetReferralOrManager),
    fork(watchRejectCelebritySaga),
    fork(watchCelebritySearchList),
    fork(watchGetCelebrityDetails),
  ]);
}
