import jwt_decode from "jwt-decode";

const toFamososEmail = (user = "") => `${user}@famosos.com`;

const devTeamMembers = [
  "german",
  "isaac",
  "cesar.flores",
  "jhon",
  "william",
  "jorge",
];
const qaTeamMembers = ["dvazquez", "ana.yepez"];

export class Session {
  constructor() {
    this.sessionName =
      process.env.REACT_APP_ENVIRONMENT === "production" ? "prod_fs" : "dev_fs"; // famosos session name
    this.session = this.getSession();
    this.acceptedUserType = "ADMIN";
  }

  setSession = (token) => {
    localStorage.setItem(this.sessionName, token);
  };

  getSession = () => {
    const token = localStorage.getItem(this.sessionName);
    return token ? this.jwtDecode(token) : null;
  };

  removeSession = () => {
    localStorage.removeItem(this.sessionName);
  };

  tokenExpired() {
    const userSession = this.getSession();
    if (
      this.utcSecondsToDateTime(userSession.exp) <= new Date() ||
      userSession.userType !== this.acceptedUserType
    ) {
      this.removeSession();
      return true;
    }
    return false;
  }

  utcSecondsToDateTime = (utcSeconds) => {
    if (utcSeconds) {
      const date = new Date(0); // The 0 there is the key, which sets the date to the epoch
      date.setUTCSeconds(utcSeconds);
      return date;
    } else {
      return null;
    }
  };

  jwtDecode(token) {
    try {
      if (token) {
        return jwt_decode(token);
      }
    } catch (e) {
      return null;
    }
  }

  isFullAdmin = () => {
    const fullAdminUsers = ["duvan", "andres", "heinz", "juan.atuesta"];
    return fullAdminUsers.map(toFamososEmail).includes(this.session.email);
  };

  hasAccessToCoupons = () => {
    const accessToCouponsUsers = [
      "danea",
      "desiree",
      "vero",
      "dvazquez",
      "maria.diaz",
      "jose",
      "rodrigo",
      "laura",
      "andreani",
      "santiago",
      "juliana",
      "fabiannalovera",
      "paulina",
      "andres.bello",
      "mateo.arango",
    ];
    return (
      this.isFullAdmin() ||
      accessToCouponsUsers.map(toFamososEmail).includes(this.session.email)
    );
  };

  hasAccessToAllOperations = () => {
    const accessToAllOperationsUsers = ["vero", "dvazquez"];
    return (
      this.isFullAdmin() ||
      accessToAllOperationsUsers
        .map(toFamososEmail)
        .includes(this.session.email)
    );
  };

  hasAccessToExperiencesOperations = () => {
    const accessToExperiencesOperationsUsers = ["juliana", ...devTeamMembers];
    return (
      this.isFullAdmin() ||
      accessToExperiencesOperationsUsers
        .map(toFamososEmail)
        .includes(this.session.email)
    );
  };

  hasAccessToCelebrityDataForm = () => {
    return true;
    const accessToCelebrityDataFormUsers = ["maria.diaz", "laura", ...devTeamMembers];

    if (process.env.REACT_APP_ENVIRONMENT !== "production") {
      accessToCelebrityDataFormUsers.push(...qaTeamMembers);
    }

    return (
      this.isFullAdmin() ||
      accessToCelebrityDataFormUsers
        .map(toFamososEmail)
        .includes(this.session.email)
    );
  };
}
