export const MODEL_PATH = "celebrity-transactions";

//##############################################################
// CRUD
export const POST = MODEL_PATH + "_POST";
export const POST_SUCCESS = MODEL_PATH + "_POST_SUCCESS";
export const POST_ERROR = MODEL_PATH + "_POST_ERROR";
export const LIST = MODEL_PATH + "_LIST";
export const LIST_SUCCESS = MODEL_PATH + "_LIST_SUCCESS";
export const LIST_ERROR = MODEL_PATH + "_LIST_ERROR";
export const GET = MODEL_PATH + "_GET";
export const GET_SUCCESS = MODEL_PATH + "_GET_SUCCESS";
export const GET_ERROR = MODEL_PATH + "_GET_ERROR";
export const PUT = MODEL_PATH + "_PUT";
export const PUT_SUCCESS = MODEL_PATH + "_PUT_SUCCESS";
export const PUT_ERROR = MODEL_PATH + "_PUT_ERROR";
export const DELETE_ONE = MODEL_PATH + "_DELETE_ONE";
export const DELETE_ONE_SUCCESS = MODEL_PATH + "_DELETE_ONE_SUCCESS";
export const DELETE_ONE_ERROR = MODEL_PATH + "_DELETE_ONE_ERROR";

// END CRUD
//##############################################################\

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom action constants


export const LIST_CELEBRITY_BALANCES =
    MODEL_PATH + "_LIST_CELEBRITY_BALANCES";
export const LIST_CELEBRITY_BALANCES_SUCCESS =
    MODEL_PATH + "_LIST_CELEBRITY_BALANCES_SUCCESS";
export const LIST_CELEBRITY_BALANCES_ERROR =
    MODEL_PATH + "_LIST_CELEBRITY_BALANCES_ERROR";
export const REMOVE_ELEMENT_LIST_CELEBRITY_BALANCES =
    MODEL_PATH + "_REMOVE_ELEMENT_LIST_CELEBRITY_BALANCES";

export const LIST_CELEBRITY_TRANSACTIONS =
    MODEL_PATH + "_LIST_CELEBRITY_TRANSACTIONS";
export const LIST_CELEBRITY_TRANSACTIONS_SUCCESS =
    MODEL_PATH + "_LIST_CELEBRITY_TRANSACTIONS_SUCCESS";
export const LIST_CELEBRITY_TRANSACTIONS_ERROR =
    MODEL_PATH + "_LIST_CELEBRITY_TRANSACTIONS_ERROR";
export const REMOVE_ELEMENT_LIST_CELEBRITY_TRANSACTIONS =
    MODEL_PATH + "_REMOVE_ELEMENT_LIST_CELEBRITY_TRANSACTIONS";

export const LIST_COMPLETED_PAYMENTS =
    MODEL_PATH + "_LIST_COMPLETED_PAYMENTS";
export const LIST_COMPLETED_PAYMENTS_SUCCESS =
    MODEL_PATH + "_LIST_COMPLETED_PAYMENTS_SUCCESS";
export const LIST_COMPLETED_PAYMENTS_ERROR =
    MODEL_PATH + "_LIST_COMPLETED_PAYMENTS_ERROR";

export const NEW_PAYMENT_TRANSACTION =
    MODEL_PATH + "_NEW_PAYMENT_TRANSACTION";
export const NEW_PAYMENT_TRANSACTION_SUCCESS =
    MODEL_PATH + "_NEW_PAYMENT_TRANSACTION_SUCCESS";
export const NEW_PAYMENT_TRANSACTION_ERROR =
    MODEL_PATH + "_NEW_PAYMENT_TRANSACTION_ERROR";

// CUSTOM ACTIONS
// ##############################################################
