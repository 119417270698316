import {
    CREATE,
    CREATE_ERROR,
    CREATE_SUCCESS,
    DELETE_CELEBRITY_SECTION,
    DELETE_CELEBRITY_SECTION_ERROR,
    DELETE_CELEBRITY_SECTION_SUCCESS,
    DELETE_ONE,
    DELETE_ONE_ERROR,
    DELETE_ONE_SUCCESS,
    GET,
    GET_CELEBRITY_SECTION_DATA,
    GET_CELEBRITY_SECTION_DATA_ERROR,
    GET_CELEBRITY_SECTION_DATA_SUCCESS,
    GET_ERROR,
    GET_SUCCESS,
    LIST,
    LIST_ERROR,
    LIST_SUCCESS,
    NOT_PAGINATED_LIST,
    NOT_PAGINATED_LIST_ERROR,
    NOT_PAGINATED_LIST_SUCCESS,
    POST,
    POST_ERROR,
    POST_SUCCESS,
    PUT,
    PUT_ERROR,
    PUT_SUCCESS,
    RESET_CREATE_ERROR,
    RESET_UPDATE_ERROR,
    UPDATE,
    UPDATE_ERROR,
    UPDATE_POSITIONS,
    UPDATE_POSITIONS_ERROR,
    UPDATE_POSITIONS_SUCCESS,
    UPDATE_SUCCESS
} from "./constants";

//##############################################################
// CRUD
export const post = (payload) => ({
    type: POST,
    payload: payload
});
export const postSuccess = (payload) => ({
    type: POST_SUCCESS,
    payload: payload
});
export const postError = (payload) => ({
    type: POST_ERROR,
    payload: payload
});
export const put = (payload) => ({
    type: PUT,
    payload: payload
});
export const putSuccess = (payload) => ({
    type: PUT_SUCCESS,
    payload: payload
});
export const putError = (payload) => ({
    type: PUT_ERROR,
    payload: payload
});
export const list = (payload) => ({
    type: LIST,
    payload: payload
});
export const listSuccess = (payload) => ({
    type: LIST_SUCCESS,
    payload: payload
});
export const listError = (payload) => ({
    type: LIST_ERROR,
    payload: payload
});
export const get = (objectID, params) => ({
    type: GET,
    payload: {objectID, params}
});
export const getSuccess = (payload) => ({
    type: GET_SUCCESS,
    payload: payload
});
export const getError = (payload) => ({
    type: GET_ERROR,
    payload: payload
});
export const deleteOne = (payload) => ({
    type: DELETE_ONE,
    payload: payload
});
export const deleteOneSuccess = (payload) => ({
    type: DELETE_ONE_SUCCESS,
    payload: payload
});
export const deleteOneError = (payload) => ({
    type: DELETE_ONE_ERROR,
    payload: payload
});
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom actions
export const create = (payload, history) => ({
    type: CREATE,
    payload,
    history
});
export const createSuccess = (payload) => ({
    type: CREATE_SUCCESS,
    payload
});
export const createError = (payload) => ({
    type: CREATE_ERROR,
    payload: payload
});
export const notPaginatedList = (payload) => ({
    type: NOT_PAGINATED_LIST,
    payload
});

export const notPaginatedListSuccess = (payload) => ({
    type: NOT_PAGINATED_LIST_SUCCESS,
    payload
});

export const notPaginatedListError = (payload) => ({
    type: NOT_PAGINATED_LIST_ERROR,
    payload
});

export const updatePositions = (payload, history) => ({
    type: UPDATE_POSITIONS,
    payload,
    history
});

export const updatePositionsSuccess = (payload) => ({
    type: UPDATE_POSITIONS_SUCCESS,
    payload
});

export const updatePositionsError = (payload) => ({
    type: UPDATE_POSITIONS_ERROR,
    payload
});

export const update = (payload, history) => ({
    type: UPDATE,
    payload,
    history
});

export const updateSuccess = (payload) => ({
    type: UPDATE_SUCCESS,
    payload
});

export const updateError = (payload) => ({
    type: UPDATE_ERROR,
    payload
});

export const getCelebritySectionData = (payload) => ({
    type: GET_CELEBRITY_SECTION_DATA,
    payload
});

export const getCelebritySectionDataSuccess = (payload) => ({
    type: GET_CELEBRITY_SECTION_DATA_SUCCESS,
    payload
});

export const getCelebritySectionDataError = (payload) => ({
    type: GET_CELEBRITY_SECTION_DATA_ERROR,
    payload
});

export const deleteCelebritySection = (payload, history) => ({
    type: DELETE_CELEBRITY_SECTION,
    payload,
    history
});

export const deleteCelebritySectionSuccess = (payload) => ({
    type: DELETE_CELEBRITY_SECTION_SUCCESS,
    payload
});

export const deleteCelebritySectionError = (payload) => ({
    type: DELETE_CELEBRITY_SECTION_ERROR,
    payload
});

export const resetCreateError = () => ({
    type: RESET_CREATE_ERROR
});

export const resetUpdateError = () => ({
    type: RESET_UPDATE_ERROR
});
// CUSTOM ACTIONS
// ##############################################################
