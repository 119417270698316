import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {LOGIN, LOGOUT} from "./constants";
import {Session} from "../session";

import {loginError, loginSuccess} from "./actions";
import {ROOT_PATH} from "../../routing/paths";
import apiService from "../api_service";
import {LOGIN_PATH} from "./paths";

//#######################
// LOGIN
export function* watchLogin() {
    yield takeEvery(LOGIN, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
    return new Promise(function (resolve, reject) {
        apiService({
            async: true,
            path: LOGIN_PATH,
            method: "POST",
            params: null,
            body: {email, password}
        })
            .then((res) => resolve(res.data))
            .catch((err) => {
                reject(err.response ? err.response.data : {error: "Server Error"});
            });
    });
};

function* loginWithEmailPassword({payload, history}) {
    const {email, password} = payload;
    try {
        const loginData = yield call(loginWithEmailPasswordAsync, email, password);
        if (loginData.status !== "ERROR") {
            const session = new Session();
            session.setSession(loginData.token);
            yield put(loginSuccess(loginData.token));
            history.push(ROOT_PATH);
        } else {
            yield put(loginError(loginData.error));
        }
    } catch (error) {
        yield put(loginError(error));
    }
}

// END LOGIN
//#######################

//#######################
// LOGOUT
export function* watchLogout() {
    yield takeEvery(LOGOUT, logoutAsyc);
}

function* logoutAsyc({payload: history}) {
    try {
        new Session().removeSession();
        history.push(ROOT_PATH);
    } catch (error) {
    }
}

// END LOGOUT
//#######################

export default function* rootSaga() {
    yield all([fork(watchLogin), fork(watchLogout)]);
}
