export const MODEL_PATH = "notifications";

export const LIST_VIEWS = MODEL_PATH + "_LIST_VIEWS";
export const LIST_VIEWS_ERROR = MODEL_PATH + "_LIST_VIEWS_ERROR";
export const LIST_VIEWS_SUCCESS = MODEL_PATH + "_LIST_VIEWS_SUCCESS";

export const SEND_PUSH_NOTIFICATIONS = MODEL_PATH + "_SEND_PUSH_NOTIFICATIONS";
export const SEND_PUSH_NOTIFICATIONS_ERROR =
    MODEL_PATH + "_SEND_PUSH_NOTIFICATIONS_ERROR";
export const SEND_PUSH_NOTIFICATIONS_SUCCESS =
    MODEL_PATH + "_SEND_PUSH_NOTIFICATIONS_SUCCESS";

export const LIST_PUSH_NOTIFICATIONS_SEND =
    MODEL_PATH + "_LIST_PUSH_NOTIFICATIONS_SEND";
export const LIST_PUSH_NOTIFICATIONS_SEND_ERROR =
    MODEL_PATH + "_LIST_PUSH_NOTIFICATIONS_SEND_ERROR";
export const LIST_PUSH_NOTIFICATIONS_SEND_SUCCESS =
    MODEL_PATH + "_LIST_PUSH_NOTIFICATIONS_SEND_SUCCESS";

export const SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST =
    MODEL_PATH + "_SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST";
export const SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_ERROR =
    MODEL_PATH + "_SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_ERROR";
export const SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_SUCCESS =
    MODEL_PATH + "_SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_SUCCESS";

export const TEST_QUERY_BUILDER_FILTERS =
    MODEL_PATH + "_TEST_QUERY_BUILDER_FILTERS";
export const TEST_QUERY_BUILDER_FILTERS_ERROR =
    MODEL_PATH + "_TEST_QUERY_BUILDER_FILTERS_ERROR";
export const TEST_QUERY_BUILDER_FILTERS_SUCCESS =
    MODEL_PATH + "_TEST_QUERY_BUILDER_FILTERS_SUCCESS";
