import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import apiService from "../api_service";
import {DELETE_ONE, GET, LIST, LIST_CONTRACT_PAYMENTS, POST, PUT} from "./constants";
import {
    deleteOneError,
    deleteOneSuccess,
    getError,
    getSuccess,
    listContractPaymentsError,
    listContractPaymentsSuccess,
    listError,
    listSuccess,
    postError,
    postSuccess,
    putError,
    putSuccess
} from "./actions";
import {
    CRUD_DELETE_PATH,
    CRUD_GET_PATH,
    CRUD_LIST_PATH,
    CRUD_POST_PATH,
    CRUD_PUT_PATH,
    PAYMENTS_SUMMARY
} from "./paths";

import {COUPONS_DETAILS_PATH} from "../../routing/paths";

//#######################
// LIST
export function* watchList() {
    yield takeEvery(LIST, listSaga);
}

export const listSagaAsync = async (params) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_LIST_PATH,
            params: params
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* listSaga({payload}) {
    try {
        const response = yield call(listSagaAsync, payload);
        if (response.status === "OK") {
            yield put(listSuccess(response));
        } else {
            yield put(listError(response));
        }
    } catch (e) {
        yield put(listError(e.data ? e.data : {}));
    }
}

// END LIST
//#######################

//#######################
// LIST
export function* watchListContractPayments() {
    yield takeEvery(LIST_CONTRACT_PAYMENTS, listContractPaymentsSaga);
}

export const listContractPaymentsSagaAsync = async (params) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_LIST_PATH,
            params: params
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* listContractPaymentsSaga({payload}) {
    try {
        const response = yield call(listContractPaymentsSagaAsync, payload);
        if (response.status === "OK") {
            yield put(listContractPaymentsSuccess(response));
        } else {
            yield put(listContractPaymentsError(response));
        }
    } catch (e) {
        yield put(listContractPaymentsError(e.data ? e.data : {}));
    }
}

// END LIST
//#######################

//#######################
// GET
export function* watchGet() {
    yield takeEvery(GET, getSaga);
}

const getSagaAsync = async (objectId) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_GET_PATH + objectId
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* getSaga({payload}) {
    try {
        const response = yield call(getSagaAsync, payload);
        if (response.status === "OK") {
            yield put(getSuccess(response));
        } else {
            yield put(getError(response));
        }
    } catch (e) {
        yield put(getError(e.data ? e.data : {}));
    }
}

// END GET
//#######################

//#######################
// POST
export function* watchPost() {
    yield takeEvery(POST, postSaga);
}

export const postSagaAsync = async (body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "POST",
            path: CRUD_POST_PATH,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* postSaga({payload, history}) {
    try {
        const response = yield call(postSagaAsync, payload);
        if (response.status === "OK") {
            yield put(postSuccess(response));
            // history.push(COUPONS_DETAILS_PATH.replace(":id", response.data.id))
        } else {
            yield put(postError(response));
        }
    } catch (e) {
        yield put(postError(e.data ? e.data : {}));
    }
}

// END POST
//#######################

//#######################
// PUT
export function* watchPut() {
    yield takeEvery(PUT, putSaga);
}

export const putSagaAsync = async (body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "PUT",
            path: CRUD_PUT_PATH + body.id,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* putSaga({payload, history}) {
    if (payload.isPercentageDiscount) {
        payload.discount_amount = payload.discount_amount / 100;
    }
    try {
        const response = yield call(putSagaAsync, payload);
        if (response.status === "OK") {
            yield put(putSuccess(response));
            history.push(COUPONS_DETAILS_PATH.replace(":id", response.data.id));
        } else {
            yield put(putError(response));
        }
    } catch (e) {
        yield put(putError(e.data ? e.data : {}));
    }
}

// END PUT
//#######################

//#######################
// DELETE
export function* watchDeleteOne() {
    yield takeEvery(DELETE_ONE, deleteOneSaga);
}

export const deleteOneSagaAsync = async (objectId) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "DELETE",
            path: CRUD_DELETE_PATH + objectId
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* deleteOneSaga({payload}) {
    try {
        const response = yield call(deleteOneSagaAsync, payload);
        if (response.status === "OK") {
            yield put(deleteOneSuccess(response));
        } else {
            yield put(deleteOneError(response));
        }
    } catch (e) {
        yield put(deleteOneError(e.data ? e.data : {}));
    }
}

// END DELETE
//#######################


export const paymentsSummarySagaAsync = (userId) =>
    apiService({
        method: "GET",
        path: PAYMENTS_SUMMARY + userId,
    }).then((response) => response.data.data)
        .catch((err) => err.response ? err.response.data : {error: err.message});

export default function* rootSaga() {
    yield all([
        fork(watchList),
        fork(watchListContractPayments),
        fork(watchGet),
        fork(watchPut),
        fork(watchPost),
        fork(watchDeleteOne)
    ]);
}
