import {MODEL_PATH} from "./constants";

//##############################################################
// CRUD
export const CRUD_LIST_PATH = "crud/list/" + MODEL_PATH;
export const CRUD_POST_PATH = "crud/post/" + MODEL_PATH;
export const CRUD_GET_PATH = "crud/get/" + MODEL_PATH + "/";
export const CRUD_PUT_PATH = "crud/put/" + MODEL_PATH + "/";
export const CRUD_DELETE_PATH = "crud/delete/" + MODEL_PATH + "/";
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom paths
export const LIST_PATH = `custom-endpoints/${MODEL_PATH}/list`;

export const GET_BY_USERNAME_PATH =
    "custom-endpoints/" + MODEL_PATH + "/get-user-by-username/";
export const GET_INSTAGRAM_MEDIAS_PATH =
    "custom-endpoints/" + MODEL_PATH + "/get-user-instagram-medias/";
export const CREATE_PATH = "custom-endpoints/" + MODEL_PATH + "/create";
export const GET_CELEBRITIES_PATH =
    "custom-endpoints/" + MODEL_PATH + "/:MANAGER_ID/celebrities";
export const GET_CONTRACTS_PATH =
    "custom-endpoints/" + MODEL_PATH + "/:MANAGER_ID/contracts";
export const GET_MANAGER_AGGREMENT_PATH = `/custom-endpoints/${MODEL_PATH}/:MANAGER_ID/manager-agreement`;
export const GET_CONTRACTS_BALANCE_PATH =
    "custom-endpoints/" + MODEL_PATH + "/:MANAGER_ID/contracts-balance";
export const GET_CONTRACTS_GROUP_BY_STATUS_PATH = `/custom-endpoints/${MODEL_PATH}/:MANAGER_ID/contracts-group-by-status`;
export const GET_CELEBRITIES_GROUP_BY_STATUS_SUMMARY = `/custom-endpoints/${MODEL_PATH}/:MANAGER_ID/celebrities-group-by-status`;
export const LINK_CELEBRITIES_PATH =
    "custom-endpoints/" + MODEL_PATH + "/:MANAGER_ID/link-celebrities";

export const UPDATE_PATH = `custom-endpoints/${MODEL_PATH}/:MANAGER_ID/edit`;
// CUSTOM ACTIONS
// ##############################################################
