import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import apiService from "../api_service";
import {ACCEPT_CONTRACT, DELETE_ONE, GET, LIST, POST, PUT, REJECT_CONTRACT} from "./constants";
import {
    acceptContractError,
    acceptContractSuccess,
    deleteOneError,
    deleteOneSuccess,
    getError,
    getSuccess,
    listError,
    listSuccess,
    postError,
    postSuccess,
    putError,
    putSuccess,
    rejectContractError,
    rejectContractSuccess
} from "./actions";
import {
    CRUD_DELETE_PATH,
    CRUD_GET_PATH,
    CRUD_LIST_PATH,
    CRUD_POST_PATH,
    CRUD_PUT_PATH,
    CUSTOM_ENDPOINTS_ACCEPT_CONTRACT_PATH,
    CUSTOM_ENDPOINTS_ACCEPT_OR_REJECT_CONTRACT_PATH,
    CUSTOM_ENDPOINTS_ACTIVE_CONTRACT,
    CUSTOM_ENDPOINTS_CUSTOM_UPDATE,
    CUSTOM_ENDPOINTS_GET_UPLOAD_LINK,
    CUSTOM_ENDPOINTS_MARK_AS_CHARGEBACK,
    CUSTOM_ENDPOINTS_REJECT_AND_BLOCK_USER,
    CUSTOM_ENDPOINTS_REJECT_CONTRACT_PATH,
    CUSTOM_ENDPOINTS_REMOVE_CONTRACT_MEDIA_CACHE,
    CUSTOM_ENDPOINTS_REQUEST_URL_FOR_POST,
    CUSTOM_ENDPOINTS_POST_URL_VIDEO_RACTION,
    CRUD_LIST_PATH_REACTIONS,
    CUSTOM_ENDPOINTS_MARK_CONTRACT_AS_FRAUD_REVISION_PATH,
    CUSTOM_ENDPOINTS_REACTIVATE_FRAUD_REVISION_CONTRACT_PATH
} from "./paths";

import {CELEBRITIES_EDITOR_PATH} from "../../routing/paths";

//#######################
// LIST
export function* watchList() {
    yield takeEvery(LIST, listSaga);
}

export const listSagaAsync = async (params) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_LIST_PATH,
            params: params
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* listSaga({payload}) {
    try {
        const response = yield call(listSagaAsync, payload);
        if (response.status === "OK") {
            yield put(listSuccess(response));
        } else {
            yield put(listError(response));
        }
    } catch (e) {
        yield put(listError(e.data ? e.data : {}));
    }
}

// END LIST
//#######################

//#######################
// GET
export function* watchGet() {
    yield takeEvery(GET, getSaga);
}

const getSagaAsync = async (objectId) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_GET_PATH + objectId
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* getSaga({payload}) {
    try {
        const response = yield call(getSagaAsync, payload);
        if (response.status === "OK") {
            yield put(getSuccess(response));
        } else {
            yield put(getError(response));
        }
    } catch (e) {
        yield put(getError(e.data ? e.data : {}));
    }
}

// END GET
//#######################

//#######################
// POST
export function* watchPost() {
    yield takeEvery(POST, postSaga);
}

const postSagaAsync = async (body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "POST",
            path: CRUD_POST_PATH,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* postSaga({payload, history}) {
    try {
        const response = yield call(postSagaAsync, payload);
        if (response.status === "OK") {
            yield put(postSuccess(response));
            history.push(CELEBRITIES_EDITOR_PATH.replace(":id", response.data.id));
        } else {
            yield put(postError(response));
        }
    } catch (e) {
        yield put(postError(e.data ? e.data : {}));
    }
}

// END POST
//#######################

//#######################
// PUT
export function* watchPut() {
    yield takeEvery(PUT, putSaga);
}

const putSagaAsync = async (body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "PUT",
            path: CRUD_PUT_PATH + body.id,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* putSaga({payload}) {
    try {
        const response = yield call(putSagaAsync, payload);
        if (response.status === "OK") {
            yield put(putSuccess(response));
        } else {
            yield put(putError(response));
        }
    } catch (e) {
        yield put(putError(e.data ? e.data : {}));
    }
}

// END PUT
//#######################

//#######################
// DELETE
export function* watchDeleteOne() {
    yield takeEvery(DELETE_ONE, deleteOneSaga);
}

const deleteOneSagaAsync = async (objectId) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "DELETE",
            path: CRUD_DELETE_PATH + objectId
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* deleteOneSaga({payload}) {
    try {
        const response = yield call(deleteOneSagaAsync, payload);
        if (response.status === "OK") {
            yield put(deleteOneSuccess(response));
        } else {
            yield put(deleteOneError(response));
        }
    } catch (e) {
        yield put(deleteOneError(e.data ? e.data : {}));
    }
}

// END DELETE
//#######################

const acceptOrRejectContractSagaAsync = (body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "POST",
            path: CUSTOM_ENDPOINTS_ACCEPT_OR_REJECT_CONTRACT_PATH,
            body
        })
            .then((res) => resolve({...res.data, media: body.mediaURL}))
            .catch((err) =>
                reject(err.response ? err.response : {error: err.message})
            );
    });
};

export function* watchAcceptContract() {
    yield takeEvery(ACCEPT_CONTRACT, acceptContractSaga);
}

function* acceptContractSaga({payload}) {
    try {
        const response = yield call(acceptOrRejectContractSagaAsync, payload);
        if (response.status === "OK") {
            yield put(acceptContractSuccess(response));
        } else {
            yield put(acceptContractError(response));
        }
    } catch (error) {
        yield put(acceptContractError(error.data || error));
    }
}

export function* watchRejectContract() {
    yield takeEvery(REJECT_CONTRACT, rejectContractSaga);
}

function* rejectContractSaga({payload}) {
    try {
        const response = yield call(acceptOrRejectContractSagaAsync, payload);
        if (response.status === "OK") {
            yield put(rejectContractSuccess(response));
        } else {
            yield put(rejectContractError(response));
        }
    } catch (error) {
        yield put(rejectContractError(error.data || error));
    }
}

export const getUploadLinkSagaAsync = (contractReference, fileExtension) =>
    apiService({
        method: "POST",
        path: CUSTOM_ENDPOINTS_GET_UPLOAD_LINK,
        body: {
            contractReference,
            fileExtension,
            actionData: "UPLOAD"
        }
    }).then((response) => response.data.data);


export const activeContractSagaAsync = (contractReference) =>
    apiService({
        method: "POST",
        path: CUSTOM_ENDPOINTS_ACTIVE_CONTRACT,
        body: {
            contractReference: contractReference
        }
    }).then((response) => response.data.data)
        .catch((err) => err.response ? err.response.data : {error: err.message});

export const rejectContractSagaAsync = (contractReference, celebrityId) =>
    apiService({
        method: "POST",
        path: CUSTOM_ENDPOINTS_REJECT_CONTRACT_PATH,
        body: {
            celebrityId: celebrityId,
            contractReference: contractReference
        }
    }).then((response) => response.data.data)
        .catch((err) => err.response ? err.response.data : {error: err.message});

export const acceptContractSagaAsync = (contractReference, celebrityId) =>
    apiService({
        method: "POST",
        path: CUSTOM_ENDPOINTS_ACCEPT_CONTRACT_PATH,
        body: {
            celebrityId: celebrityId,
            contractReference: contractReference
        }
    }).then((response) => response.data.data)
        .catch((err) => err.response ? err.response.data : {error: err.message});

export const removeVideoCacheSagaAsync = (contractReference) =>
    apiService({
        method: "POST",
        path: CUSTOM_ENDPOINTS_REMOVE_CONTRACT_MEDIA_CACHE,
        body: {
            contractReference: contractReference
        }
    }).then((response) => response.data.data)
        .catch((err) => err.response ? err.response.data : {error: err.message});

export const customUpdateSagaAsync = (data) =>
    apiService({
        method: "PUT",
        path: CUSTOM_ENDPOINTS_CUSTOM_UPDATE,
        body: data
    }).then((response) => response.data.data)
        .catch((err) => err.response ? err.response.data : {error: err.message});

export const markContractAsChargebackSagaAsync = (data) =>
    apiService({
        method: "PUT",
        path: CUSTOM_ENDPOINTS_MARK_AS_CHARGEBACK,
        body: data
    }).then((response) => response.data.data)
        .catch((err) => err.response ? err.response.data : {error: err.message});

export const rejectAndBlockUserButton = (data) =>
    apiService({
        "method": "PUT",
        "path": CUSTOM_ENDPOINTS_REJECT_AND_BLOCK_USER,
        "body": data
    }).then((response) => response.data.data)
        .catch((err) => err.response ? err.response.data : {error: err.message});

export const markContractAsFraudRevision = (data) =>
  apiService({
    method: "PUT",
    path: CUSTOM_ENDPOINTS_MARK_CONTRACT_AS_FRAUD_REVISION_PATH,
    body: data
  })
    .then((response) => response.data.data)
    .catch((err) =>
      err.response ? err.response.data : { error: err.message }
    );

export const reactivateFraudRevisionContract = (contractReference) =>
    apiService({
    method: "POST",
    path: CUSTOM_ENDPOINTS_REACTIVATE_FRAUD_REVISION_CONTRACT_PATH,
    body: {contractReference}
    })
    .then((response) => response.data.data)
    .catch((err) =>
        err.response ? err.response.data : { error: err.message }
    );



// URL POST AMAZON


export const requestUrlFormPostSagaAsync = (contractReference,fileExtension) =>
    apiService({
        method: "POST",
        path: CUSTOM_ENDPOINTS_REQUEST_URL_FOR_POST,
        body: {
            contractReference,
            fileExtension
        }
    }).then((response) => response.data.data)
        .catch((err) => err.response ? err.response.data : {error: err.message});

export const postUrlVideoReactions = (contractReference,reactionUrl)=>
    apiService({
        method: "POST",
        path: CUSTOM_ENDPOINTS_POST_URL_VIDEO_RACTION,
        body: {
            contractReference,
            reactionUrl
        }
}).then((response) => response.data.data)
    .catch((err) => err.response ? err.response.data : {error: err.message});

export const listVideoReactions = async (params) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_LIST_PATH_REACTIONS,
            params
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

export default function* rootSaga() {
    yield all([
        fork(watchList),
        fork(watchGet),
        fork(watchPut),
        fork(watchPost),
        fork(watchDeleteOne),
        fork(watchAcceptContract),
        fork(watchRejectContract)
    ]);
}
