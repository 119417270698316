import axios from "axios";
import {Session} from "./session";

const setHeaders = (meta) => {
    const session = new Session();
    const params = meta.params;
    const headers = meta.headers ? meta.headers : {};
    let options = {};

    if (session.getSession()) {
        headers["authorization"] =
            "Bearer " + localStorage.getItem(session.sessionName);
    }
    if (jsonToQueryString(params) !== "?") {
        options.params = params;
    }
    options.headers = headers;

    return options;
};

export const jsonToQueryString = (json) => {
    if (json) {
        return (
            "?" +
            Object.keys(json)
                .map(function (key) {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
                })
                .join("&")
        );
    }
    return "";
};

export const queryStringToJSON = (query_string) => {
    const pairs = query_string.slice(1).split("&");
    const result = {};
    pairs.forEach(function (pair) {
        pair = pair.split("=");
        result[pair[0]] = decodeURIComponent(pair[1] || "");
    });
    return JSON.parse(JSON.stringify(result));
};

function getEndpointUrl(meta) {
    const REACT_APP_ENDPOINT = process.env.REACT_APP_ENDPOINT;
    const baseUrl = REACT_APP_ENDPOINT.endsWith("/")
        ? REACT_APP_ENDPOINT
        : REACT_APP_ENDPOINT + "/";

    const { path } = meta;

    const validPath = path.startsWith("/") ? path.substr(1) : path;

    return `${baseUrl}${validPath}`;
}

const apiService = (meta) => {
    // Path is Required
    if (!meta.path) {
        throw new Error(`'path' not specified for async action ${meta.action}`);
    }

    // Final URL
    let url = meta.path;
    if (!meta.custom_endpoint) {
        url = getEndpointUrl(meta);
    } else {
        url = meta.custom_endpoint;
    }
    const source = meta.isCancellable ? axios.CancelToken.source() : null;
    let request;
    switch (meta.method) {
        case "GET":
        default:
            request = axios.get(url, setHeaders(meta));
            break;
        case "POST":
            request = axios.post(url, meta.body, setHeaders(meta));
            break;
        case "PUT":
            request = axios.put(url, meta.body, setHeaders(meta));
            break;
        case "DELETE":
            request = axios.delete(url, setHeaders(meta));
            break;
    }

    if (meta.isCancellable) request.cancel = source.cancel;
    return request;
};

export default apiService;
