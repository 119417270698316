import {
    CREATE,
    CREATE_ERROR,
    CREATE_SUCCESS,
    DELETE_ONE,
    DELETE_ONE_ERROR,
    DELETE_ONE_SUCCESS,
    GET,
    GET_BY_USERNAME,
    GET_BY_USERNAME_ERROR,
    GET_BY_USERNAME_SUCCESS,
    GET_CELEBRITIES,
    GET_CELEBRITIES_ERROR,
    GET_CELEBRITIES_SUCCESS,
    GET_CONTRACTS,
    GET_CONTRACTS_BALANCE,
    GET_CONTRACTS_BALANCE_ERROR,
    GET_CONTRACTS_BALANCE_SUCCESS,
    GET_CONTRACTS_ERROR,
    GET_CONTRACTS_SUCCESS,
    GET_ERROR,
    GET_SUCCESS,
    LINK_CELEBRITIES,
    LINK_CELEBRITIES_ERROR,
    LINK_CELEBRITIES_SUCCESS,
    LIST,
    LIST_ERROR,
    LIST_SUCCESS,
    POST,
    POST_ERROR,
    POST_SUCCESS,
    PUT,
    PUT_ERROR,
    PUT_SUCCESS,
    UPDATE,
    UPDATE_ERROR,
    UPDATE_SUCCESS
} from "./constants";

//##############################################################
// CRUD
export const post = (payload) => ({
    type: POST,
    payload: payload
});
export const postSuccess = (payload) => ({
    type: POST_SUCCESS,
    payload: payload
});
export const postError = (payload) => ({
    type: POST_ERROR,
    payload: payload
});
export const put = (payload) => ({
    type: PUT,
    payload: payload
});
export const putSuccess = (payload) => ({
    type: PUT_SUCCESS,
    payload: payload
});
export const putError = (payload) => ({
    type: PUT_ERROR,
    payload: payload
});
export const list = (payload) => ({
    type: LIST,
    payload: payload
});
export const listSuccess = (payload) => ({
    type: LIST_SUCCESS,
    payload: payload
});
export const listError = (payload) => ({
    type: LIST_ERROR,
    payload: payload
});
export const get = (payload) => ({
    type: GET,
    payload: payload
});
export const getSuccess = (payload) => ({
    type: GET_SUCCESS,
    payload: payload
});
export const getError = (payload) => ({
    type: GET_ERROR,
    payload: payload
});
export const deleteOne = (payload) => ({
    type: DELETE_ONE,
    payload: payload
});
export const deleteOneSuccess = (payload) => ({
    type: DELETE_ONE_SUCCESS,
    payload: payload
});
export const deleteOneError = (payload) => ({
    type: DELETE_ONE_ERROR,
    payload: payload
});
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom actions
export const getByUsername = (objectID, params) => ({
    type: GET_BY_USERNAME,
    payload: {objectID, params}
});
export const getByUsernameSuccess = (payload) => ({
    type: GET_BY_USERNAME_SUCCESS,
    payload: payload
});
export const getByUsernameError = (payload) => ({
    type: GET_BY_USERNAME_ERROR,
    payload: payload
});
export const getCelebrities = (managerId, params) => ({
    type: GET_CELEBRITIES,
    payload: {managerId, params}
});
export const getCelebritiesSuccess = (payload) => ({
    type: GET_CELEBRITIES_SUCCESS,
    payload: payload
});
export const getCelebritiesError = (payload) => ({
    type: GET_CELEBRITIES_ERROR,
    payload: payload
});
export const getContracts = (managerId, params) => ({
    type: GET_CONTRACTS,
    payload: {managerId, params}
});
export const getContractsSuccess = (payload) => ({
    type: GET_CONTRACTS_SUCCESS,
    payload: payload
});
export const getContractsError = (payload) => ({
    type: GET_CONTRACTS_ERROR,
    payload: payload
});
export const getContractsBalance = (managerId, params) => ({
    type: GET_CONTRACTS_BALANCE,
    payload: {managerId, params}
});
export const getContractsBalanceSuccess = (payload) => ({
    type: GET_CONTRACTS_BALANCE_SUCCESS,
    payload: payload
});
export const getContractsBalanceError = (payload) => ({
    type: GET_CONTRACTS_BALANCE_ERROR,
    payload: payload
});
export const create = (payload, history) => ({
    type: CREATE,
    payload,
    history
});
export const createSuccess = (payload) => ({
    type: CREATE_SUCCESS,
    payload
});
export const createError = (payload) => ({
    type: CREATE_ERROR,
    payload: payload
});
export const linkCelebrities = (payload, history) => ({
    type: LINK_CELEBRITIES,
    payload,
    history
});
export const linkCelebritiesSuccess = (payload) => ({
    type: LINK_CELEBRITIES_SUCCESS,
    payload
});
export const linkCelebritiesError = (payload) => ({
    type: LINK_CELEBRITIES_ERROR,
    payload
});
export const update = (payload, history) => {
    return {
        type: UPDATE,
        payload,
        history
    };
};

export const updateSuccess = (payload) => ({
    type: UPDATE_SUCCESS,
    payload
});

export const updateError = (payload) => ({
    type: UPDATE_ERROR,
    payload
});
// CUSTOM ACTIONS
// ##############################################################
