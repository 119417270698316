import {
    ACCEPT_CONTRACT,
    ACCEPT_CONTRACT_ERROR,
    ACCEPT_CONTRACT_SUCCESS,
    DELETE_ONE,
    DELETE_ONE_ERROR,
    DELETE_ONE_SUCCESS,
    GET,
    GET_ERROR,
    GET_SUCCESS,
    LIST,
    LIST_ERROR,
    LIST_SUCCESS,
    POST,
    POST_ERROR,
    POST_SUCCESS,
    PUT,
    PUT_ERROR,
    PUT_SUCCESS,
    REJECT_CONTRACT,
    REJECT_CONTRACT_ERROR,
    REJECT_CONTRACT_SUCCESS,
    RESET_REJECT_CONTRACT,
    RESTART_CONTRACT_VIDEO_UPLOAD,
    SET_VIDEO_UPLOAD_PROGRESS,
    START_CONTRACT_VIDEO_UPLOAD
} from "./constants";

//##############################################################
// CRUD
export const post = (payload, history) => ({
    type: POST,
    payload: payload,
    history
});
export const postSuccess = (payload) => ({
    type: POST_SUCCESS,
    payload: payload
});
export const postError = (payload) => ({
    type: POST_ERROR,
    payload: payload
});
export const put = (payload) => ({
    type: PUT,
    payload: payload
});
export const putSuccess = (payload) => ({
    type: PUT_SUCCESS,
    payload: payload
});
export const putError = (payload) => ({
    type: PUT_ERROR,
    payload: payload
});
export const list = (payload) => ({
    type: LIST,
    payload: payload
});
export const listSuccess = (payload) => ({
    type: LIST_SUCCESS,
    payload: payload
});
export const listError = (payload) => ({
    type: LIST_ERROR,
    payload: payload
});
export const get = (payload) => ({
    type: GET,
    payload: payload
});
export const getSuccess = (payload) => ({
    type: GET_SUCCESS,
    payload: payload
});
export const getError = (payload) => ({
    type: GET_ERROR,
    payload: payload
});
export const deleteOne = (payload) => ({
    type: DELETE_ONE,
    payload: payload
});
export const deleteOneSuccess = (payload) => ({
    type: DELETE_ONE_SUCCESS,
    payload: payload
});
export const deleteOneError = (payload) => ({
    type: DELETE_ONE_ERROR,
    payload: payload
});
// END CRUD
//##############################################################

export const startContractVideoUpload = () => ({
    type: START_CONTRACT_VIDEO_UPLOAD
});

export const acceptContract = (payload) => ({
    type: ACCEPT_CONTRACT,
    payload: {...payload, status: 30}
});

export const setVideoUploadProgress = (payload) => ({
    type: SET_VIDEO_UPLOAD_PROGRESS,
    payload
});

export const acceptContractSuccess = (payload) => ({
    type: ACCEPT_CONTRACT_SUCCESS,
    payload: payload
});

export const acceptContractError = (payload) => ({
    type: ACCEPT_CONTRACT_ERROR,
    payload: payload
});

export const restartContractVideoUpload = () => ({
    type: RESTART_CONTRACT_VIDEO_UPLOAD
});

export const rejectContract = (payload) => ({
    type: REJECT_CONTRACT,
    payload: {...payload, status: 20}
});

export const rejectContractSuccess = (payload) => ({
    type: REJECT_CONTRACT_SUCCESS,
    payload: payload
});

export const rejectContractError = (payload) => ({
    type: REJECT_CONTRACT_ERROR,
    payload: payload
});

export const resetRejectContract = (payload) => ({
    type: RESET_REJECT_CONTRACT
});
