import {
    GET,
    GET_ERROR,
    GET_SUCCESS,
    LIST,
    LIST_ERROR,
    LIST_SUCCESS,
    POST,
    POST_ERROR,
    POST_SUCCESS,
    PUT,
    PUT_ERROR,
    PUT_SUCCESS
} from "./constants";

//##############################################################
// CRUD

export const post = (payload: Record<string, unknown>) => ({
    type: POST,
    payload: payload
});

export const postSuccess = (payload: Record<string, unknown>) => ({
    type: POST_SUCCESS,
    payload: payload
});

export const postError = (payload: Record<string, unknown>) => ({
    type: POST_ERROR,
    payload: payload
});

export const put = (payload: Record<string, unknown>) => ({
    type: PUT,
    payload: payload
});
export const putSuccess = (payload: Record<string, unknown>) => ({
    type: PUT_SUCCESS,
    payload: payload
});
export const putError = (payload: Record<string, unknown>) => ({
    type: PUT_ERROR,
    payload: payload
});
export const list = (payload: Record<string, unknown>) => ({
    type: LIST,
    payload: payload
});
export const listSuccess = (payload: Record<string, unknown>) => ({
    type: LIST_SUCCESS,
    payload: payload
});
export const listError = (payload: Record<string, unknown>) => ({
    type: LIST_ERROR,
    payload: payload
});
export const get = (payload: { id: string }) => ({
    type: GET,
    payload
});
export const getSuccess = (payload: Record<string, unknown>) => ({
    type: GET_SUCCESS,
    payload: payload
});
export const getError = (payload: Record<string, unknown>) => ({
    type: GET_ERROR,
    payload: payload
});

// END CRUD
//##############################################################
