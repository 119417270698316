import {
    LOGIN,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTER,
    REGISTER_ERROR,
    REGISTER_SUCCESS,
    SET_REDIRECT_UNAUTHORIZED
} from "./constants";

//##############################################################
// CRUD

// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom actions
export const login = (payload, history) => ({
    type: LOGIN,
    payload: payload,
    history
});
export const loginSuccess = (payload) => ({
    type: LOGIN_SUCCESS,
    payload: payload
});
export const loginError = (payload) => ({
    type: LOGIN_ERROR,
    payload: payload
});
export const register = (payload, history) => ({
    type: REGISTER,
    payload: payload,
    history
});
export const registerSuccess = (payload) => ({
    type: REGISTER_SUCCESS,
    payload: payload
});
export const registerError = (payload) => ({
    type: REGISTER_ERROR,
    payload: payload
});
export const logout = (payload) => ({
    type: LOGOUT,
    payload
});

export const setRedirectUnauthorized = (payload) => ({
    type: SET_REDIRECT_UNAUTHORIZED,
    payload
});
// CUSTOM ACTIONS
// ##############################################################
