import { MODEL_PATH } from "./constants";

//##############################################################
// CRUD
export const CRUD_LIST_PATH = "crud/list/" + MODEL_PATH;
export const CRUD_POST_PATH = "crud/post/" + MODEL_PATH;
export const CRUD_GET_PATH = "crud/get/" + MODEL_PATH + "/";
export const CRUD_PUT_PATH = "crud/put/" + MODEL_PATH + "/";
export const CRUD_DELETE_PATH = "crud/delete/" + MODEL_PATH + "/";
export const CUSTOM_ENDPOINTS_SAVE =
	"custom-endpoints/" + MODEL_PATH + "/create";
export const CUSTOM_ENDPOINTS_UPDATE =
	"custom-endpoints/" + MODEL_PATH + "/update";
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom paths

// CUSTOM ACTIONS
// ##############################################################
