import {
  CELEBRITY_SEARCH_LIST,
  CELEBRITY_SEARCH_LIST_ERROR,
  CELEBRITY_SEARCH_LIST_SUCCESS,
  DELETE_ONE,
  DELETE_ONE_CELEBRITY_BY_ID,
  DELETE_ONE_ERROR,
  DELETE_ONE_SUCCESS,
  GET,
  GET_BY_USERNAME,
  GET_BY_USERNAME_ERROR,
  GET_BY_USERNAME_SUCCESS,
  GET_CELEBRITY_DETAILS,
  GET_CELEBRITY_DETAILS_ERROR,
  GET_CELEBRITY_DETAILS_SUCCESS,
  GET_CONTRACTS_BALANCE,
  GET_CONTRACTS_BALANCE_ERROR,
  GET_CONTRACTS_BALANCE_SUCCESS,
  GET_CONTRACTS_GROUP_BY_STATUS,
  GET_CONTRACTS_GROUP_BY_STATUS_ERROR,
  GET_CONTRACTS_GROUP_BY_STATUS_SUCCESS,
  GET_ERROR,
  GET_REFERRAL_OR_MANAGER,
  GET_REFERRAL_OR_MANAGER_ERROR,
  GET_REFERRAL_OR_MANAGER_SUCCESS,
  GET_SUCCESS,
  LIST,
  LIST_ERROR,
  LIST_SUCCESS,
  POST,
  POST_ERROR,
  POST_SUCCESS,
  PUT,
  PUT_ERROR,
  PUT_SUCCESS,
  REJECT_CELEBRITY,
  REJECT_CELEBRITY_ERROR,
  REJECT_CELEBRITY_SUCCESS,
} from "./constants";

//##############################################################
// CRUD
export const post = (payload, history) => ({
  type: POST,
  payload: payload,
  history,
});
export const postSuccess = (payload) => ({
  type: POST_SUCCESS,
  payload: payload,
});
export const postError = (payload) => ({
  type: POST_ERROR,
  payload: payload,
});
export const put = (payload) => ({
  type: PUT,
  payload: payload,
});
export const putSuccess = (payload) => ({
  type: PUT_SUCCESS,
  payload: payload,
});
export const putError = (payload) => ({
  type: PUT_ERROR,
  payload: payload,
});
export const list = (payload) => ({
  type: LIST,
  payload: payload,
});
export const listSuccess = (payload) => ({
  type: LIST_SUCCESS,
  payload: payload,
});
export const listError = (payload) => ({
  type: LIST_ERROR,
  payload: payload,
});
export const get = (objectID, params) => ({
  type: GET,
  payload: { objectID, params },
});
export const getSuccess = (payload) => ({
  type: GET_SUCCESS,
  payload: payload,
});
export const getError = (payload) => ({
  type: GET_ERROR,
  payload: payload,
});
export const deleteOne = (payload) => ({
  type: DELETE_ONE,
  payload: payload,
});
export const deleteOneSuccess = (payload) => ({
  type: DELETE_ONE_SUCCESS,
  payload: payload,
});
export const deleteOneError = (payload) => ({
  type: DELETE_ONE_ERROR,
  payload: payload,
});
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom actions

export const getCelebrityDetails = (objectID, params) => ({
  type: GET_CELEBRITY_DETAILS,
  payload: { objectID, params },
});
export const getCelebrityDetailsSuccess = (payload) => ({
  type: GET_CELEBRITY_DETAILS_SUCCESS,
  payload: payload,
});
export const getCelebrityDetailsError = (payload) => ({
  type: GET_CELEBRITY_DETAILS_ERROR,
  payload: payload,
});

export const getByUsername = (objectID, params) => ({
  type: GET_BY_USERNAME,
  payload: { objectID, params },
});
export const getByUsernameSuccess = (payload) => ({
  type: GET_BY_USERNAME_SUCCESS,
  payload: payload,
});
export const getByUsernameError = (payload) => ({
  type: GET_BY_USERNAME_ERROR,
  payload: payload,
});
export const getContractsGroupByStatus = (celebrityId, params) => ({
  type: GET_CONTRACTS_GROUP_BY_STATUS,
  payload: { celebrityId, params },
});
export const getContractsGroupByStatusSuccess = (payload) => ({
  type: GET_CONTRACTS_GROUP_BY_STATUS_SUCCESS,
  payload: payload,
});
export const getContractsGroupByStatusError = (payload) => ({
  type: GET_CONTRACTS_GROUP_BY_STATUS_ERROR,
  payload: payload,
});
export const getContractsBalance = (celebrityId, params) => ({
  type: GET_CONTRACTS_BALANCE,
  payload: { celebrityId, params },
});
export const getContractsBalanceSuccess = (payload) => ({
  type: GET_CONTRACTS_BALANCE_SUCCESS,
  payload: payload,
});
export const getContractsBalanceError = (payload) => ({
  type: GET_CONTRACTS_BALANCE_ERROR,
  payload: payload,
});

export const getReferralOrManager = (payload) => ({
  type: GET_REFERRAL_OR_MANAGER,
  payload: payload,
});
export const getReferralOrManagerSuccess = (payload) => ({
  type: GET_REFERRAL_OR_MANAGER_SUCCESS,
  payload: payload,
});
export const getReferralOrManagerError = (payload) => ({
  type: GET_REFERRAL_OR_MANAGER_ERROR,
  payload: payload,
});

export const rejectCelebrity = (payload) => ({
  type: REJECT_CELEBRITY,
  payload: payload,
});
export const rejectCelebritySuccess = (payload) => ({
  type: REJECT_CELEBRITY_SUCCESS,
  payload: payload,
});
export const rejectCelebrityError = (payload) => ({
  type: REJECT_CELEBRITY_ERROR,
  payload: payload,
});
export const deleteOneCelebrityById = (payload) => ({
  type: DELETE_ONE_CELEBRITY_BY_ID,
  payload: payload,
});

export const celebritySearchList = (payload) => ({
  type: CELEBRITY_SEARCH_LIST,
  payload: payload,
});
export const celebritySearchListSuccess = (payload) => ({
  type: CELEBRITY_SEARCH_LIST_SUCCESS,
  payload: payload,
});
export const celebritySearchListError = (payload) => ({
  type: CELEBRITY_SEARCH_LIST_ERROR,
  payload: payload,
});

// CUSTOM ACTIONS
// ##############################################################
