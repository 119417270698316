import {MODEL_PATH} from "./constants";

//##############################################################
// CRUD
export const CRUD_LIST_PATH = "crud/list/" + MODEL_PATH;
export const CRUD_POST_PATH = "crud/post/" + MODEL_PATH;
export const CRUD_GET_PATH = "crud/get/" + MODEL_PATH + "/";
export const CRUD_POST_OR_UPDATE_LIST_PATH = "crud/create-or-update-list/" + MODEL_PATH;
export const CRUD_PUT_PATH = "crud/put/" + MODEL_PATH + "/";
export const CRUD_DELETE_PATH = "crud/delete/" + MODEL_PATH + "/";
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom paths

// CUSTOM ACTIONS
// ##############################################################
