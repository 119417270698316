import {
  DELETE_ONE,
  DELETE_ONE_ERROR,
  DELETE_ONE_SUCCESS,
  GET,
  GET_ERROR,
  GET_SUCCESS,
  LIST,
  LIST_ERROR,
  LIST_SUCCESS,
  POST,
  POST_ERROR,
  POST_SUCCESS,
  PUT,
  PUT_ERROR,
  PUT_SUCCESS,
  LIST_CELEBRITY_BALANCES,
  LIST_CELEBRITY_BALANCES_SUCCESS,
  LIST_CELEBRITY_BALANCES_ERROR,
  NEW_PAYMENT_TRANSACTION,
  NEW_PAYMENT_TRANSACTION_SUCCESS,
  NEW_PAYMENT_TRANSACTION_ERROR,
  REMOVE_ELEMENT_LIST_CELEBRITY_BALANCES,
  LIST_COMPLETED_PAYMENTS,
  LIST_COMPLETED_PAYMENTS_SUCCESS,
  LIST_COMPLETED_PAYMENTS_ERROR,
  LIST_CELEBRITY_TRANSACTIONS,
  LIST_CELEBRITY_TRANSACTIONS_SUCCESS,
  LIST_CELEBRITY_TRANSACTIONS_ERROR
} from "./constants";

//##############################################################
// CRUD
export const post = (payload, history) => ({
  type: POST,
  payload: payload,
  history
});
export const postSuccess = (payload) => ({
  type: POST_SUCCESS,
  payload: payload
});
export const postError = (payload) => ({
  type: POST_ERROR,
  payload: payload
});
export const put = (payload) => ({
  type: PUT,
  payload: payload
});
export const putSuccess = (payload) => ({
  type: PUT_SUCCESS,
  payload: payload
});
export const putError = (payload) => ({
  type: PUT_ERROR,
  payload: payload
});
export const list = (payload) => ({
  type: LIST,
  payload: payload
});
export const listSuccess = (payload) => ({
  type: LIST_SUCCESS,
  payload: payload
});
export const listError = (payload) => ({
  type: LIST_ERROR,
  payload: payload
});
export const get = (payload) => ({
  type: GET,
  payload: payload
});
export const getSuccess = (payload) => ({
  type: GET_SUCCESS,
  payload: payload
});
export const getError = (payload) => ({
  type: GET_ERROR,
  payload: payload
});
export const deleteOne = (payload) => ({
  type: DELETE_ONE,
  payload: payload
});
export const deleteOneSuccess = (payload) => ({
  type: DELETE_ONE_SUCCESS,
  payload: payload
});
export const deleteOneError = (payload) => ({
  type: DELETE_ONE_ERROR,
  payload: payload
});
// END CRUD
//##############################################################

// CUSTOM ACTIONS
// TODO: Write here your custom actions


export const listCelebrityBalances = (payload) => ({
  type: LIST_CELEBRITY_BALANCES,
  payload: payload
});

export const listCelebrityBalancesSuccess = (payload) => ({
  type: LIST_CELEBRITY_BALANCES_SUCCESS,
  payload: payload
});

export const listCelebrityBalancesError = (payload) => ({
  type: LIST_CELEBRITY_BALANCES_ERROR,
  payload: payload
});

export const removeElementListCelebrityBalances = (payload) => ({
  type: REMOVE_ELEMENT_LIST_CELEBRITY_BALANCES,
  payload: payload
});

export const listCompletedPayments = (payload) => ({
  type: LIST_COMPLETED_PAYMENTS,
  payload: payload
});

export const listCompletedPaymentsSuccess = (payload) => ({
  type: LIST_COMPLETED_PAYMENTS_SUCCESS,
  payload: payload
});

export const listCompletedPaymentsError = (payload) => ({
  type: LIST_COMPLETED_PAYMENTS_ERROR,
  payload: payload
});


export const listCelebrityTransactions = (payload) => ({
  type: LIST_CELEBRITY_TRANSACTIONS,
  payload: payload
});

export const listCelebrityTransactionsSuccess = (payload) => ({
  type: LIST_CELEBRITY_TRANSACTIONS_SUCCESS,
  payload: payload
});

export const listCelebrityTransactionsError = (payload) => ({
  type: LIST_CELEBRITY_TRANSACTIONS_ERROR,
  payload: payload
});



// 
export const newPaymentTransaction = (payload) => ({
  type: NEW_PAYMENT_TRANSACTION,
  payload: payload
});

export const newPaymentTransactionSuccess = (payload) => ({
  type: NEW_PAYMENT_TRANSACTION_SUCCESS,
  payload: payload
});

export const newPaymentTransactionError = (payload) => ({
  type: NEW_PAYMENT_TRANSACTION_ERROR,
  payload: payload
});



  // CUSTOM ACTIONS
  // ##############################################################
