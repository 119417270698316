import {
    LOGIN,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTER,
    REGISTER_ERROR,
    REGISTER_SUCCESS,
    SET_REDIRECT_UNAUTHORIZED
} from "./constants";
import {NotificationManager} from "../../components/common/react-notifications";
import {LOGIN_INITIAL_STATE, REGISTER_INITIAL_STATE, SET_REDIRECT_UNAUTHORIZED_INITIAL_STATE} from "../initial_states";

const INIT_STATE = {
    // CRUD

    // CUSTOM ACTIONS
    login: LOGIN_INITIAL_STATE,
    register: REGISTER_INITIAL_STATE,
    setRedirectUnauthorized: SET_REDIRECT_UNAUTHORIZED_INITIAL_STATE
};

export default (state = INIT_STATE, action) => {
    let res;
    switch (action.type) {
        //##############################################################
        // CRUD

        // CRUD
        //##############################################################

        //##############################################################
        // CUSTOM ACTIONS
        // TODO: Write here your custom cases
        case LOGIN:
            res = {...state};
            res.login = {...LOGIN_INITIAL_STATE};
            res.login.loading = true;
            return res;
        case LOGIN_SUCCESS:
            res = {...state};
            res.login = {...LOGIN_INITIAL_STATE};
            res.login.token = action.payload.token;
            return res;
        case LOGIN_ERROR:
            res = {...state};
            res.login = {...LOGIN_INITIAL_STATE};
            res.login.errorData = action.payload;
            NotificationManager.warning(
                res.login.errorData.error,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        case REGISTER:
            res = {...state};
            res.register = {...REGISTER_INITIAL_STATE};
            res.register.loading = true;
            return res;
        case REGISTER_SUCCESS:
            res = {...state};
            res.register = {...REGISTER_INITIAL_STATE};
            res.register.token = action.payload.token;
            return res;
        case REGISTER_ERROR:
            res = {...state};
            res.register = {...REGISTER_INITIAL_STATE};
            res.register.errorData = action.payload;
            NotificationManager.warning(
                res.register.errorData.error,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        case SET_REDIRECT_UNAUTHORIZED:
            res = {...state};
            res.setRedirectUnauthorized.shouldRedirectUnauthorized = action.payload;
            return res;
        case LOGOUT:
            return {...state};
        // CUSTOM ACTIONS
        // ##############################################################
        default:
            return {...state};
    }
};
