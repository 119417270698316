import {
    LIST_PUSH_NOTIFICATIONS_SEND,
    LIST_PUSH_NOTIFICATIONS_SEND_ERROR,
    LIST_PUSH_NOTIFICATIONS_SEND_SUCCESS,
    LIST_VIEWS,
    LIST_VIEWS_ERROR,
    LIST_VIEWS_SUCCESS,
    SEND_PUSH_NOTIFICATIONS,
    SEND_PUSH_NOTIFICATIONS_ERROR,
    SEND_PUSH_NOTIFICATIONS_SUCCESS,
    SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST,
    SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_ERROR,
    SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_SUCCESS,
    TEST_QUERY_BUILDER_FILTERS,
    TEST_QUERY_BUILDER_FILTERS_ERROR,
    TEST_QUERY_BUILDER_FILTERS_SUCCESS
} from "./constants";

//##############################################################
// CUSTOM ENDPOINTS
export const listViews = (payload) => {
    return {
        type: LIST_VIEWS,
        payload: payload
    };
};
export const listViewsSuccess = (payload) => ({
    type: LIST_VIEWS_SUCCESS,
    payload: payload
});
export const listViewsError = (payload) => ({
    type: LIST_VIEWS_ERROR,
    payload: payload
});
export const sendPushNotifications = (payload, history) => {
    return {
        type: SEND_PUSH_NOTIFICATIONS,
        payload: payload,
        history
    };
};
export const sendPushNotificationsSuccess = (payload) => ({
    type: SEND_PUSH_NOTIFICATIONS_SUCCESS,
    payload: payload
});
export const sendPushNotificationsError = (payload) => ({
    type: SEND_PUSH_NOTIFICATIONS_ERROR,
    payload: payload
});

export const sendPushNotificationsWithCustomList = (payload, history) => {
    return {
        type: SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST,
        payload: payload,
        history
    };
};
export const sendPushNotificationsWithCustomListSuccess = (payload) => ({
    type: SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_SUCCESS,
    payload: payload
});
export const sendPushNotificationsWithCustomListError = (payload) => ({
    type: SEND_PUSH_NOTIFICATIONS_WITH_CUSTOM_LIST_ERROR,
    payload: payload
});

export const listPushNotificationsSent = (payload) => {
    return {
        type: LIST_PUSH_NOTIFICATIONS_SEND,
        payload: payload
    };
};
export const listPushNotificationsSentSuccess = (payload) => ({
    type: LIST_PUSH_NOTIFICATIONS_SEND_SUCCESS,
    payload: payload
});
export const listPushNotificationsSentError = (payload) => ({
    type: LIST_PUSH_NOTIFICATIONS_SEND_ERROR,
    payload: payload
});

export const testQueryBuilderFilters = (payload) => {
    console.log("[testQueryBuilderFilters]");
    return {
        type: TEST_QUERY_BUILDER_FILTERS,
        payload: payload
    };
};
export const testQueryBuilderFiltersSuccess = (payload) => ({
    type: TEST_QUERY_BUILDER_FILTERS_SUCCESS,
    payload: payload
});
export const testQueryBuilderFiltersError = (payload) => ({
    type: TEST_QUERY_BUILDER_FILTERS_ERROR,
    payload: payload
});

// END CUSTOM ENDPOINTS
//##############################################################
