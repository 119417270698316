export const MODEL_PATH = "celebrities";

//##############################################################
// CRUD
export const POST = MODEL_PATH + "_POST";
export const POST_SUCCESS = MODEL_PATH + "_POST_SUCCESS";
export const POST_ERROR = MODEL_PATH + "_POST_ERROR";
export const LIST = MODEL_PATH + "_LIST";
export const LIST_SUCCESS = MODEL_PATH + "_LIST_SUCCESS";
export const LIST_ERROR = MODEL_PATH + "_LIST_ERROR";
export const GET = MODEL_PATH + "_GET";
export const GET_SUCCESS = MODEL_PATH + "_GET_SUCCESS";
export const GET_ERROR = MODEL_PATH + "_GET_ERROR";
export const PUT = MODEL_PATH + "_PUT";
export const PUT_SUCCESS = MODEL_PATH + "_PUT_SUCCESS";
export const PUT_ERROR = MODEL_PATH + "_PUT_ERROR";
export const DELETE_ONE = MODEL_PATH + "_DELETE_ONE";
export const DELETE_ONE_SUCCESS = MODEL_PATH + "_DELETE_ONE_SUCCESS";
export const DELETE_ONE_ERROR = MODEL_PATH + "_DELETE_ONE_ERROR";
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom action constants
export const GET_CELEBRITY_DETAILS = MODEL_PATH + "_GET_CELEBRITY_DETAILS";
export const GET_CELEBRITY_DETAILS_SUCCESS =
  MODEL_PATH + "_GET_CELEBRITY_DETAILS_SUCCESS";
export const GET_CELEBRITY_DETAILS_ERROR =
  MODEL_PATH + "_GET_CELEBRITY_DETAILS_ERROR";
export const GET_BY_USERNAME = MODEL_PATH + "_GET_BY_USERNAME";
export const GET_BY_USERNAME_SUCCESS = MODEL_PATH + "_GET_BY_USERNAME_SUCCESS";
export const GET_BY_USERNAME_ERROR = MODEL_PATH + "_GET_BY_USERNAME_ERROR";
export const GET_CONTRACTS_GROUP_BY_STATUS =
  MODEL_PATH + "_GET_CONTRACTS_GROUP_BY_STATUS";
export const GET_CONTRACTS_GROUP_BY_STATUS_SUCCESS =
  MODEL_PATH + "_GET_CONTRACTS_GROUP_BY_STATUS_SUCCESS";
export const GET_CONTRACTS_GROUP_BY_STATUS_ERROR =
  MODEL_PATH + "_GET_CONTRACTS_GROUP_BY_STATUS_ERROR";
export const GET_CONTRACTS_BALANCE = MODEL_PATH + "_GET_CONTRACTS_BALANCE";
export const GET_CONTRACTS_BALANCE_SUCCESS =
  MODEL_PATH + "_GET_CONTRACTS_BALANCE_SUCCESS";
export const GET_CONTRACTS_BALANCE_ERROR =
  MODEL_PATH + "_GET_CONTRACTS_BALANCE_ERROR";

export const GET_REFERRAL_OR_MANAGER = MODEL_PATH + "_GET_REFERRAL_OR_MANAGER";
export const GET_REFERRAL_OR_MANAGER_SUCCESS =
  MODEL_PATH + "_GET_REFERRAL_OR_MANAGER_SUCCESS";
export const GET_REFERRAL_OR_MANAGER_ERROR =
  MODEL_PATH + "_GET_REFERRAL_OR_MANAGER_ERROR";

export const REJECT_CELEBRITY = MODEL_PATH + "_REJECT_CELEBRITY";
export const REJECT_CELEBRITY_SUCCESS =
  MODEL_PATH + "_REJECT_CELEBRITY__SUCCESS";
export const REJECT_CELEBRITY_ERROR = MODEL_PATH + "_REJECT_CELEBRITY_ERROR";

export const CELEBRITY_SEARCH_LIST = MODEL_PATH + "_CELEBRITY_SEARCH_LIST";
export const CELEBRITY_SEARCH_LIST_SUCCESS =
  MODEL_PATH + "_CELEBRITY_SEARCH_LIST__SUCCESS";
export const CELEBRITY_SEARCH_LIST_ERROR =
  MODEL_PATH + "_CELEBRITY_SEARCH_LIST_ERROR";

export const DELETE_ONE_CELEBRITY_BY_ID =
  MODEL_PATH + "_DELETE_ONE_CELEBRITY_BY_ID";

// CUSTOM ACTIONS
// ##############################################################
