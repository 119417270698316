import {
  DELETE_ONE_INITIAL_STATE,
  GET_CONTRACTS_BALANCE_INITIAL_STATE,
  GET_CONTRACTS_GROUP_BY_STATUS_INITIAL_STATE,
  GET_INITIAL_STATE,
  LIST_INITIAL_STATE,
  POST_INITIAL_STATE,
  PUT_INITIAL_STATE
} from "../initial_states";
import { NotificationManager } from "../../components/common/react-notifications";
import {
  CELEBRITY_SEARCH_LIST,
  CELEBRITY_SEARCH_LIST_ERROR,
  CELEBRITY_SEARCH_LIST_SUCCESS,
  DELETE_ONE,
  DELETE_ONE_CELEBRITY_BY_ID,
  DELETE_ONE_ERROR,
  DELETE_ONE_SUCCESS,
  GET,
  GET_BY_USERNAME,
  GET_BY_USERNAME_ERROR,
  GET_BY_USERNAME_SUCCESS,
  GET_CELEBRITY_DETAILS,
  GET_CELEBRITY_DETAILS_ERROR,
  GET_CELEBRITY_DETAILS_SUCCESS,
  GET_CONTRACTS_BALANCE,
  GET_CONTRACTS_BALANCE_ERROR,
  GET_CONTRACTS_BALANCE_SUCCESS,
  GET_CONTRACTS_GROUP_BY_STATUS,
  GET_CONTRACTS_GROUP_BY_STATUS_ERROR,
  GET_CONTRACTS_GROUP_BY_STATUS_SUCCESS,
  GET_ERROR,
  GET_REFERRAL_OR_MANAGER,
  GET_REFERRAL_OR_MANAGER_ERROR,
  GET_REFERRAL_OR_MANAGER_SUCCESS,
  GET_SUCCESS,
  LIST,
  LIST_ERROR,
  LIST_SUCCESS,
  POST,
  POST_ERROR,
  POST_SUCCESS,
  PUT,
  PUT_ERROR,
  PUT_SUCCESS,
  REJECT_CELEBRITY,
  REJECT_CELEBRITY_ERROR,
  REJECT_CELEBRITY_SUCCESS
} from "./constants";

const INIT_STATE = {
  // CRUD
  post: POST_INITIAL_STATE,
  put: PUT_INITIAL_STATE,
  list: LIST_INITIAL_STATE,
  get: GET_INITIAL_STATE,
  deleteOne: DELETE_ONE_INITIAL_STATE,
  // CUSTOM ACTIONS
  getCelebrityDetails: GET_INITIAL_STATE,
  getByUsername: GET_INITIAL_STATE,
  getContractsGroupByStatus: GET_CONTRACTS_GROUP_BY_STATUS_INITIAL_STATE,
  getContractsBalance: GET_CONTRACTS_BALANCE_INITIAL_STATE,
  getReferralOrManager: GET_INITIAL_STATE,
  rejectCelebrity: DELETE_ONE_INITIAL_STATE,
  searchCelebrityList: LIST_INITIAL_STATE
};

export default (state = INIT_STATE, action) => {
  let res;
  switch (action.type) {
    //##############################################################
    // CRUD
    case POST:
      res = { ...state };
      res.post = { ...POST_INITIAL_STATE };
      res.post.loading = true;
      return res;
    case POST_SUCCESS:
      res = { ...state };
      res.post = { ...POST_INITIAL_STATE };
      res.post.data = action.payload.data;
      return res;
    case POST_ERROR:
      res = { ...state };
      res.post = { ...POST_INITIAL_STATE };
      res.post.errorData = action.payload;
      NotificationManager.warning(
        res.post.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    case PUT:
      res = { ...state };
      res.put = { ...PUT_INITIAL_STATE };
      res.put.loading = true;
      return res;
    case PUT_SUCCESS:
      res = { ...state };
      res.put = { ...PUT_INITIAL_STATE };
      res.put.data = action.payload.data;
      NotificationManager.success(
        "",
        "Celebrity Updated!",
        3000,
        null,
        null,
        ""
      );
      return res;
    case PUT_ERROR:
      res = { ...state };
      res.put = { ...PUT_INITIAL_STATE };
      res.put.errorData = action.payload;
      NotificationManager.warning(
        res.put.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    case LIST:
      res = { ...state };
      res.list = { ...LIST_INITIAL_STATE };
      res.list.loading = true;
      return res;
    case LIST_SUCCESS:
      res = { ...state };
      res.list = { ...LIST_INITIAL_STATE };
      res.list.results = action.payload.results;
      res.list.informationPage = action.payload.informationPage;
      return res;
    case LIST_ERROR:
      res = { ...state };
      res.list = { ...LIST_INITIAL_STATE };
      res.list.errorData = action.payload;
      NotificationManager.warning(
        res.list.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    case DELETE_ONE_CELEBRITY_BY_ID:
      res = { ...state };
      res.searchCelebrityList.results = res.searchCelebrityList.results.filter(
        (item) => item.id !== action.payload
      );
    case GET:
      res = { ...state };
      res.get = { ...GET_INITIAL_STATE };
      res.get.loading = true;
      return res;
    case GET_SUCCESS:
      res = { ...state };
      res.get = { ...GET_INITIAL_STATE };
      res.get.data = action.payload.data;
      return res;
    case GET_ERROR:
      res = { ...state };
      res.get = { ...GET_INITIAL_STATE };
      res.get.errorData = action.payload;
      NotificationManager.warning(
        res.get.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    case DELETE_ONE:
      res = { ...state };
      res.delete = { ...DELETE_ONE_INITIAL_STATE };
      res.delete.loading = true;
      return res;
    case DELETE_ONE_SUCCESS:
      res = { ...state };
      res.delete = { ...DELETE_ONE_INITIAL_STATE };
      res.delete.data = action.payload.data;
      return res;
    case DELETE_ONE_ERROR:
      res = { ...state };
      res.delete = { ...DELETE_ONE_INITIAL_STATE };
      res.delete.errorData = action.payload;
      NotificationManager.warning(
        res.delete.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    // CRUD
    //##############################################################

    //##############################################################
    // CUSTOM ACTIONS
    // TODO: Write here your custom cases
    case GET_BY_USERNAME:
      res = { ...state };
      res.getByUsername = { ...GET_INITIAL_STATE };
      res.getByUsername.loading = true;
      return res;
    case GET_BY_USERNAME_SUCCESS:
      res = { ...state };
      res.getByUsername = { ...GET_INITIAL_STATE };
      res.getByUsername.data = action.payload.data;
      return res;
    case GET_BY_USERNAME_ERROR:
      res = { ...state };
      res.getByUsername = { ...GET_INITIAL_STATE };
      res.getByUsername.errorData = action.payload;
      NotificationManager.warning(
        res.getByUsername.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    case GET_CONTRACTS_GROUP_BY_STATUS:
      res = { ...state };
      res.getContractsGroupByStatus = {
        ...GET_CONTRACTS_GROUP_BY_STATUS_INITIAL_STATE
      };
      res.getContractsGroupByStatus.loading = true;
      return res;
    case GET_CONTRACTS_GROUP_BY_STATUS_SUCCESS:
      res = { ...state };
      res.getContractsGroupByStatus = {
        ...GET_CONTRACTS_GROUP_BY_STATUS_INITIAL_STATE
      };
      res.getContractsGroupByStatus.groupByStatus = action.payload.data.reduce(
        (groupByStatus, group) => ({
          ...groupByStatus,
          [group.status]: {
            count: group.count
          }
        }),
        {
          ...GET_CONTRACTS_GROUP_BY_STATUS_INITIAL_STATE.groupByStatus
        }
      );
      return res;
    case GET_CONTRACTS_GROUP_BY_STATUS_ERROR:
      res = { ...state };
      res.getContractsGroupByStatus = {
        ...GET_CONTRACTS_GROUP_BY_STATUS_INITIAL_STATE
      };
      res.getContractsGroupByStatus.errorData = action.payload;
      return res;
    case GET_CONTRACTS_BALANCE:
      res = { ...state };
      res.getContractsBalance = {
        ...GET_CONTRACTS_BALANCE_INITIAL_STATE
      };
      res.getContractsBalance.loading = true;
      return res;
    case GET_CONTRACTS_BALANCE_SUCCESS:
      res = { ...state };
      res.getContractsBalance = {
        ...GET_CONTRACTS_BALANCE_INITIAL_STATE
      };
      res.getContractsBalance.contractsBalance = action.payload.data.reduce(
        (contractsBalance, balance) => ({
          ...contractsBalance,
          [balance.status]: balance
        }),
        {
          ...GET_CONTRACTS_BALANCE_INITIAL_STATE.contractsBalance
        }
      );
      return res;
    case GET_CONTRACTS_BALANCE_ERROR:
      res = { ...state };
      res.getContractsBalance = {
        ...GET_CONTRACTS_BALANCE_INITIAL_STATE
      };
      res.getContractsBalance.errorData = action.payload;
      return res;
    case GET_REFERRAL_OR_MANAGER:
      res = { ...state };
      res.getReferralOrManager = { ...GET_INITIAL_STATE };
      res.getReferralOrManager.loading = true;
      return res;
    case GET_REFERRAL_OR_MANAGER_SUCCESS:
      res = { ...state };
      res.getReferralOrManager = { ...GET_INITIAL_STATE };
      res.getReferralOrManager.data = action.payload.data;
      return res;
    case GET_REFERRAL_OR_MANAGER_ERROR:
      res = { ...state };
      res.getReferralOrManager = { ...GET_INITIAL_STATE };
      res.getReferralOrManager.errorData = action.payload;
      NotificationManager.warning(
        res.getReferralOrManager.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    case REJECT_CELEBRITY:
      res = { ...state };
      res.rejectCelebrity = { ...DELETE_ONE_INITIAL_STATE };
      res.rejectCelebrity.loading = true;
      return res;
    case REJECT_CELEBRITY_SUCCESS:
      res = { ...state };
      res.rejectCelebrity = { ...DELETE_ONE_INITIAL_STATE };
      res.rejectCelebrity.data = action.payload.data;
      return res;
    case REJECT_CELEBRITY_ERROR:
      res = { ...state };
      res.rejectCelebrity = { ...DELETE_ONE_INITIAL_STATE };
      res.rejectCelebrity.errorData = action.payload;
      NotificationManager.warning(
        res.rejectCelebrity.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    case CELEBRITY_SEARCH_LIST:
      res = { ...state };
      res.searchCelebrityList = { ...LIST_INITIAL_STATE };
      res.searchCelebrityList.loading = true;
      return res;
    case CELEBRITY_SEARCH_LIST_SUCCESS:
      console.log({ payloadData: action.payload });
      res = { ...state };
      res.searchCelebrityList = { ...LIST_INITIAL_STATE };
      res.searchCelebrityList.results = action.payload.results;
      res.searchCelebrityList.informationPage = action.payload.informationPage;
      return res;
    case CELEBRITY_SEARCH_LIST_ERROR:
      res = { ...state };
      res.searchCelebrityList = { ...LIST_INITIAL_STATE };
      res.searchCelebrityList.errorData = action.payload;
      NotificationManager.warning(
        res.searchCelebrityList.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    case GET_CELEBRITY_DETAILS:
      res = { ...state };
      res.getCelebrityDetails = { ...GET_INITIAL_STATE };
      res.getCelebrityDetails.loading = true;
      return res;
    case GET_CELEBRITY_DETAILS_SUCCESS:
      res = { ...state };
      res.getCelebrityDetails = { ...GET_INITIAL_STATE };
      res.getCelebrityDetails.data = Object.assign({}, action?.payload?.data, {
        hashtags: action?.payload?.data?.hashtags || []
      });
      return res;
    case GET_CELEBRITY_DETAILS_ERROR:
      res = { ...state };
      // res.getCelebrityDetails = { ...GET_INITIAL_STATE };
      // console.log("Error", action.payload);
      // console.log(action.payload);
      // res.getCelebrityDetails.errorData = { ...action.payload };
      // NotificationManager.warning(
      //   res.getCelebrityDetails.errorData.error,
      //   "Oops!",
      //   3000,
      //   null,
      //   null,
      //   ""
      // );
      return res;

    // CUSTOM ACTIONS
    // ##############################################################

    default:
      return { ...state };
  }
};
