import {MODEL_PATH} from "./constants";

//##############################################################
// CRUD
export const CRUD_LIST_PATH = "crud/list/" + MODEL_PATH;
export const CRUD_POST_PATH = "crud/post/" + MODEL_PATH;
export const CRUD_GET_PATH = "crud/get/" + MODEL_PATH + "/";
export const CRUD_PUT_PATH = "crud/put/" + MODEL_PATH + "/";
export const CRUD_DELETE_PATH = "crud/delete/" + MODEL_PATH + "/";

// END CRUD
//##############################################################

export const CONTRACTS_SUMMARY = `custom-endpoints/${MODEL_PATH}/contracts-summary/`;
export const USER_BLOCK = `custom-endpoints/${MODEL_PATH}/block-user`;
export const VERIFY_USER_PATH = `custom-endpoints/${MODEL_PATH}/verify-user`;