import {MODEL_PATH} from "./constants";

//##############################################################
// CRUD
export const CRUD_LIST_PATH = "crud/list/" + MODEL_PATH;
export const CRUD_POST_PATH = "crud/post/" + MODEL_PATH;
export const CRUD_GET_PATH = "crud/get/" + MODEL_PATH + "/";
export const CRUD_PUT_PATH = "crud/put/" + MODEL_PATH + "/";
export const CRUD_DELETE_PATH = "crud/delete/" + MODEL_PATH + "/";
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom paths

export const CREATE_CELEBRITY_TRANSACTION_PATH = `custom-endpoints/${MODEL_PATH}/create`;
export const LIST_CELEBRITY_TRANSACTIONS_PATH = `custom-endpoints/${MODEL_PATH}/list`;
export const LIST_CELEBRITY_BALANCES_PATH = `custom-endpoints/${MODEL_PATH}/list-celebrity-balances`;
export const LIST_COMPLETED_PAYMENTS_PATH = `custom-endpoints/${MODEL_PATH}/list-completed-payments`;
export const NEW_PAYMENT_TRANSACTION_PATH = `custom-endpoints/${MODEL_PATH}/new-payment-transaction`;
export const TOTAL_AMOUNT_ON_CELEBRITY_PAYMENTS_PATH = `custom-endpoints/${MODEL_PATH}/total-amount-on-celebrity-pending-payments`;
export const TOTAL_CELEBRITY_PENDING_PAYMENTS_PATH = `custom-endpoints/${MODEL_PATH}/total-celebrity-pending-payments`;
export const TOTAL_AMOUNT_ON_CELEBRITY_PAYMENTS_TODAY_PATH = `custom-endpoints/${MODEL_PATH}/total-amount-on-celebrity-payments-today`;
export const TOTAL_AMOUNT_BY_BANK_TYPE = `custom-endpoints/celebrity-transaction-balances/total-amount-by-bank-type`;
export const CELEBRITY_TOTAL_MONEY_IN_AMOUNT = `custom-endpoints/celebrity-transactions/total-amount-money-in/`;
export const CELEBRITY_TOTAL_MONEY_OUT_AMOUNT = `custom-endpoints/celebrity-transactions/total-amount-money-out/`;
export const PROCESS_MASSIVE_PAYMENTS = `custom-endpoints/celebrity-transactions/massive-payments`;

// CUSTOM ACTIONS
// ##############################################################
