import {MODEL_PATH} from "./constants";

//##############################################################
// CRUD
export const CRUD_LIST_PATH = "crud/list/" + MODEL_PATH;
export const CRUD_POST_PATH = "crud/post/" + MODEL_PATH;
export const CRUD_GET_PATH = "crud/get/" + MODEL_PATH + "/";
export const CRUD_PUT_PATH = "crud/put/" + MODEL_PATH + "/";
export const CRUD_DELETE_PATH = "crud/delete/" + MODEL_PATH + "/";

// END CRUD
//##############################################################

export const PAYMENTS_SUMMARY = `custom-endpoints/${MODEL_PATH}/payments-summary/`;
export const REASSIGN_USER_PAYMENT = `custom-endpoints/${MODEL_PATH}/reassing-payment`
