import { all } from "redux-saga/effects";
import authenticationSagas from "./authentication/saga";
import celebritySagas from "./celebrities/saga";
import countrieSagas from "./countries/saga";
import celebrityCategorySagas from "./celebrity-categories/saga";
import celebrityContractTypeSagas from "./celebrity-contract-types/saga";
import celebritySocialNetworkSagas from "./celebrity-social-networks/saga";
import contraAgreementSagas from "./contract-agreements/saga";
import celebritySectionSaga from "./celebrity-sections/saga";
import contractsSaga from "./contracts/saga";
import managersSaga from "./managers/saga";
import usersSaga from "./users/saga";
import discountCouponsSaga from "./discount-coupons/saga";
import celebrityReferralsSaga from "./celebrity-referrals/saga";
import userPaymentsSaga from "./user-payments/saga";
import businessContractsSaga from "./business-contracts/saga";
import managerAgreementsSaga from "./manager-agreements/saga";
import landingsSaga from "./landings/saga";
import notificationLogsSaga from "./notification-logs/saga";
import linksSaga from "./links/saga";
import notificationsSaga from "./notifications/saga";
import celebrityNotesSaga from "./celebrity-notes/saga";
import advancePaymentsSaga from "./advance-payment/saga";
import celebrityTransactionsSaga from "./celebrity-transactions/saga";
import celebrityAddOnsSaga from "./celebrity-add-ons/saga";
import contractAddOnsSaga from "./contract-add-ons/saga";

export default function* rootSaga(getState) {
  yield all([
    authenticationSagas(),
    celebritySagas(),
    countrieSagas(),
    managersSaga(),
    celebrityCategorySagas(),
    celebrityContractTypeSagas(),
    celebritySocialNetworkSagas(),
    contraAgreementSagas(),
    celebritySectionSaga(),
    contractsSaga(),
    usersSaga(),
    discountCouponsSaga(),
    celebrityReferralsSaga(),
    userPaymentsSaga(),
    businessContractsSaga(),
    managerAgreementsSaga(),
    landingsSaga(),
    notificationLogsSaga(),
    linksSaga(),
    notificationsSaga(),
    celebrityNotesSaga(),
    advancePaymentsSaga(),
    celebrityTransactionsSaga(),
    celebrityAddOnsSaga(),
    contractAddOnsSaga(),
  ]);
}
