import {setRedirectUnauthorized} from "./authentication/actions";

const _ERROR = "_ERROR";
const UNAUTHORIZED_ERROR = "unauthorized, a Admin Session is required";

export const invalidSessionWatcher = (store) => (next) => (action) => {
    if (
        !action.type.endsWith(_ERROR) ||
        action.payload?.error !== UNAUTHORIZED_ERROR
    ) {
        return next(action);
    }

    store.dispatch(setRedirectUnauthorized(true));
};
