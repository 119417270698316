import axios from "axios";
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import apiService from "../api_service";
import {
    GET,
    LIST,
    LIST_CELEBRITY_BALANCES,
    LIST_CELEBRITY_TRANSACTIONS,
    LIST_COMPLETED_PAYMENTS,
    NEW_PAYMENT_TRANSACTION,
    POST,
    PUT
} from "./constants";
import {
    getError,
    getSuccess,
    listCelebrityBalancesError,
    listCelebrityBalancesSuccess,
    listCelebrityTransactionsError,
    listCelebrityTransactionsSuccess,
    listCompletedPaymentsError,
    listCompletedPaymentsSuccess,
    listError,
    listSuccess,
    newPaymentTransactionError,
    newPaymentTransactionSuccess,
    postError,
    postSuccess,
    putError,
    putSuccess,
    removeElementListCelebrityBalances
} from "./actions";
import {
    CELEBRITY_TOTAL_MONEY_IN_AMOUNT,
    CELEBRITY_TOTAL_MONEY_OUT_AMOUNT,
    CRUD_GET_PATH,
    CRUD_LIST_PATH,
    CRUD_POST_PATH,
    CRUD_PUT_PATH,
    LIST_CELEBRITY_BALANCES_PATH,
    LIST_CELEBRITY_TRANSACTIONS_PATH,
    LIST_COMPLETED_PAYMENTS_PATH,
    NEW_PAYMENT_TRANSACTION_PATH,
    PROCESS_MASSIVE_PAYMENTS
} from "./paths";
import {Session} from "../session";


//#######################
// LIST
export function* watchList() {
    yield takeEvery(LIST, listSaga);
}

export const listSagaAsync = async (params) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_LIST_PATH,
            params: {...params, preloadedModel: true}
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* listSaga({payload}) {
    try {
        const response = yield call(listSagaAsync, payload);
        if (response.status === "OK") {
            yield put(listSuccess(response));
        } else {
            yield put(listError(response));
        }
    } catch (e) {
        yield put(listError(e.data ? e.data : {}));
    }
}

// END LIST
//#######################

//#######################
// GET
export function* watchGet() {
    yield takeEvery(GET, getSaga);
}

const getSagaAsync = async (param) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CRUD_GET_PATH + param,
            params: {preloadedModel: true}
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* getSaga({payload}) {
    try {
        const response = yield call(getSagaAsync, payload);
        if (response.status === "OK") {
            yield put(getSuccess(response));
        } else {
            yield put(getError(response));
        }
    } catch (e) {
        yield put(getError(e.data ? e.data : {}));
    }
}

// END GET
//#######################

//#######################
// POST
export function* watchPost() {
    yield takeEvery(POST, postSaga);
}

const postSagaAsync = async (body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "POST",
            path: CRUD_POST_PATH,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* postSaga({payload, history}) {
    try {
        const response = yield call(postSagaAsync, payload);
        if (response.status === "OK") {
            yield put(postSuccess(response));
        } else {
            yield put(postError(response));
        }
    } catch (e) {
        yield put(postError(e.data ? e.data : {}));
    }
}

// END POST
//#######################

//#######################
// PUT
export function* watchPut() {
    yield takeEvery(PUT, putSaga);
}

const putSagaAsync = async (body) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "PUT",
            path: CRUD_PUT_PATH + body.id,
            body: body
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* putSaga({payload}) {
    try {
        const response = yield call(putSagaAsync, payload);
        if (response.status === "OK") {
            yield put(putSuccess(response));
        } else {
            yield put(putError(response));
        }
    } catch (e) {
        yield put(putError(e.data ? e.data : {}));
    }
}

// END PUT
//#######################

// LIST TOTAL PENDING PAYMENTS
//#######################
export function* watchListCelebrityBalances() {
    yield takeEvery(
        LIST_CELEBRITY_BALANCES,
        listCelebrityBalances
    );
}

const listCelebrityBalancesAsync = async (payload) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: LIST_CELEBRITY_BALANCES_PATH,
            params: payload
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* listCelebrityBalances({payload}) {
    try {
        const response = yield call(listCelebrityBalancesAsync, payload);
        if (response.status === "OK") {
            yield put(listCelebrityBalancesSuccess(response));
        } else {
            yield put(listCelebrityBalancesError(response));
        }
    } catch (e) {
        yield put(listCelebrityBalancesError(e.data ? e.data : {}));
    }
}

// END  LIST TOTAL PENDING PAYMENTS
//#######################

// LIST COMPLETED PAYMENTS
//#######################
export function* watchListCompletedPayments() {
    yield takeEvery(
        LIST_COMPLETED_PAYMENTS,
        listCompletedPayments
    );
}

const listCompletedPaymentsAsync = async (payload) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: LIST_COMPLETED_PAYMENTS_PATH,
            params: payload
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* listCompletedPayments({payload}) {
    try {
        const response = yield call(listCompletedPaymentsAsync, payload);
        if (response.status === "OK") {
            yield put(listCompletedPaymentsSuccess(response));
        } else {
            yield put(listCompletedPaymentsError(response));
        }
    } catch (e) {
        yield put(listCompletedPaymentsError(e.data ? e.data : {}));
    }
}

// END LIST COMPLETED PAYMENTS
//#######################

// LIST CELEBRITY TRANSACTIONS
//#######################
export function* watchListCelebrityTransactions() {
    yield takeEvery(
        LIST_CELEBRITY_TRANSACTIONS,
        listCelebrityTransactions
    );
}

const listCelebrityTransactionsAsync = async (payload) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: LIST_CELEBRITY_TRANSACTIONS_PATH,
            params: payload
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* listCelebrityTransactions({payload}) {
    try {
        const response = yield call(listCelebrityTransactionsAsync, payload);
        if (response.status === "OK") {
            yield put(listCelebrityTransactionsSuccess(response));
        } else {
            yield put(listCelebrityTransactionsError(response));
        }
    } catch (e) {
        yield put(listCelebrityTransactionsError(e.data ? e.data : {}));
    }
}

// END LIST CELEBRITY TRANSACTIONS
//#######################

// NEW PAYMENT TRANSACTION
//#######################
export function* watchNewPaymentTransaction() {
    yield takeEvery(
        NEW_PAYMENT_TRANSACTION,
        newPaymentTransaction
    );
}

const newPaymentTransactionAsync = async (payload) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "POST",
            path: NEW_PAYMENT_TRANSACTION_PATH,
            body: payload
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

function* newPaymentTransaction({payload}) {
    try {
        const response = yield call(newPaymentTransactionAsync, payload);
        console.log({response})
        if (response.status === "OK") {
            yield put(newPaymentTransactionSuccess(response));
            yield put(
                removeElementListCelebrityBalances(response.data.celebrityId)
            );
        } else {
            yield put(newPaymentTransactionError(response));
        }
    } catch (e) {
        yield put(newPaymentTransactionError(e.data ? e.data : {}));
    }
}

// END  NEW PAYMENT TRANSACTION
//#######################

// TOTAL MONEY IN
//#######################
export const celebrityTotalMoneyInAmount = async (celebrityId) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CELEBRITY_TOTAL_MONEY_IN_AMOUNT + celebrityId,
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

// END  TOTAL MONEY IN
//#######################

// TOTAL MONEY OUT
//#######################
export const celebrityTotalMoneyOutAmount = async (celebrityId) => {
    return new Promise(function (resolve, reject) {
        apiService({
            method: "GET",
            path: CELEBRITY_TOTAL_MONEY_OUT_AMOUNT + celebrityId,
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

// END  TOTAL MONEY OUT
//#######################

// Massive Payments
//#######################
export const processMassivePayments = async (form) => {
    return new Promise(function (resolve, reject) {
        axios(
            {
                method: 'post',
                url: `${process.env.REACT_APP_ENDPOINT}${PROCESS_MASSIVE_PAYMENTS}`,
                data: form,
                headers: {
                    "authorization": "Bearer " + localStorage.getItem(new Session().sessionName),
                    "Content-Type": "multipart/form-data",
                },
            }
        )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err.response ? err.response : {}));
    });
};

// END  Massive Payments
//#######################


export default function* rootSaga() {
    yield all([
        fork(watchList),
        fork(watchGet),
        fork(watchPut),
        fork(watchPost),
        fork(watchListCelebrityBalances),
        fork(watchNewPaymentTransaction),
        fork(watchListCompletedPayments),
        fork(watchListCelebrityTransactions)
    ]);
}
